import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import PersonAvatar from '../customcomponents/PersonAvatar'
import { Icon, Modal } from 'antd'
import { getColorGradient, getDOKLevelFromGrade } from '../Util'
import { Document } from '../teacher/edvizv2/Edviz'


class DOKStandardBreakdownPrint extends Component {
    state = {
        studentDict: {},
        questionDataDict: {},
        questionData: [],
        questionsMostIncorrect: [],
        questionsMostCorrect: [],
        studentsCompleted: 0,
        visible: false,
        viewingLessonPlanItem: null,
        viewingQuestionIdx: 0
    }

    componentDidMount() {
        this.getStats()
    }

    componentWillReceiveProps(nextProps) {
        this.getStats()
    }

    showModal = (lessonPlanItem, questionIdx) => {
        this.setState({
            visible: true,
            viewingLessonPlanItem: lessonPlanItem,
            viewingQuestionIdx: questionIdx,
        })
    }

    hideModal = () => {
        this.setState({
            visible: false,
        })
    }

    studentDictHasAllStudents = (quiz) => {
        const studentIds = Object.keys(quiz.studentGrades)
        for (let i = 0; i < studentIds.length; i++) {
            const studentId = studentIds[i]
            if (!this.props.studentDict.hasOwnProperty(studentId)) {
                return false
            }
        }
        return true
    }

    areChosenAnswersCorrect = (question, chosenAnswer) => {
        const questionAnswers = question.components[0].answers
        for (let answer of chosenAnswer.answer) {
            if (!questionAnswers[answer].correct) {
                return false
            }
        }
        return true
    }

    isChosenAnswerCorrect = (questionDoc, chosenAnswersBaseQuiz) => {
        const uuid = questionDoc.metadata.uuid
        if (!chosenAnswersBaseQuiz.hasOwnProperty(uuid)) {
            return false
        }
        const chosenAnswerBaseQuestionDocId = chosenAnswersBaseQuiz[uuid]
        for (let i = 0; i < questionDoc.components[0].answers.length; i++) {
            const answer = questionDoc.components[0].answers[i]
            if (answer.correct && answer.id === chosenAnswerBaseQuestionDocId) {
                return true
            }
        }
        return false
    }

    getStudentsInDOKLevel = (quiz, dokLevel) => {
        let students = []
        let studentsDict = {}
        if (!(quiz && dokLevel)) {
            return students
        }
        if (quiz.quiz.docs.length === 0) {
            return students
        }
        let count = 0
        Object.keys(quiz.studentGrades).map((studentId) => {
            if (!quiz.studentGrades.hasOwnProperty(studentId)) {
                return false
            }
            const studentGrade = quiz.studentGrades[studentId]
            let assessmentCompleted = studentGrade.graded
            let highestDOKLevel = 1
            if (assessmentCompleted) {
                const finalGrade = studentGrade.finalGrade.grade * 100
                highestDOKLevel = getDOKLevelFromGrade(finalGrade)
            }
            if (highestDOKLevel !== dokLevel) {
                return false
            }
            if (highestDOKLevel === dokLevel && assessmentCompleted) {
                count += 1
                if (!studentsDict.hasOwnProperty(studentId)) {
                    studentsDict[studentId] = true
                    students.push(studentId)
                }
            }
            // dokLevel1 if not completed
            if (!assessmentCompleted && dokLevel === 1) {
                count += 1
                if (!studentsDict.hasOwnProperty(studentId)) {
                    studentsDict[studentId] = true
                    students.push(studentId)
                }
            }
            return false
        })

        return students
    }

    getAverageForQuestionUUID = (quiz, questionUUID) => {
        let students = []
        let studentsDict = {}
        if (!(quiz)) {
            return students
        }
        if (quiz.quiz.docs.length === 0) {
            return students
        }
        let correctCount = 0
        let count = 0
        Object.keys(quiz.studentGrades).map((studentId) => {
            if (!quiz.studentGrades.hasOwnProperty(studentId)) {
                return false
            }
            const studentGrade = quiz.studentGrades[studentId]
            let assessmentCompleted = studentGrade.graded
            let highestDOKLevel = 1
            if (assessmentCompleted) {
                const finalGrade = studentGrade.finalGrade.grade * 100
                const chosenAnswersBaseQuiz = studentGrade.chosenAnswersBaseQuiz
                for (let i = 0; i < quiz.quiz.docs.length; i++) {
                    const questionDoc = quiz.quiz.docs[i]
                    const dokLevel = questionDoc.metadata.dokLevel
                    const uuid = questionDoc.metadata.uuid
                    if (uuid !== questionUUID) {
                        continue
                    }
                    if (!chosenAnswersBaseQuiz.hasOwnProperty(uuid)) {
                        continue
                    }
                    const correct = this.isChosenAnswerCorrect(questionDoc, chosenAnswersBaseQuiz)
                    if (correct) {
                        correctCount += 1
                    }
                    count += 1
                }
            }
            return false
        })

        if (correctCount === 0 || count === 0) {
            return '0.00'
        }
        return ((correctCount / count) * 100).toFixed(2)
    }

    getAverageForQuiz = (quiz) => {
        let students = []
        let studentsDict = {}
        if (!(quiz)) {
            return students
        }
        if (quiz.quiz.docs.length === 0) {
            return students
        }
        let correctCount = 0
        let count = 0
        Object.keys(quiz.studentGrades).map((studentId) => {
            if (!quiz.studentGrades.hasOwnProperty(studentId)) {
                return false
            }
            const studentGrade = quiz.studentGrades[studentId]
            let assessmentCompleted = studentGrade.graded
            let highestDOKLevel = 1
            if (assessmentCompleted) {
                const finalGrade = studentGrade.finalGrade.grade * 100
                const chosenAnswersBaseQuiz = studentGrade.chosenAnswersBaseQuiz
                for (let i = 0; i < quiz.quiz.docs.length; i++) {
                    const questionDoc = quiz.quiz.docs[i]
                    const dokLevel = questionDoc.metadata.dokLevel
                    const uuid = questionDoc.metadata.uuid
                    if (!chosenAnswersBaseQuiz.hasOwnProperty(uuid)) {
                        continue
                    }
                    const correct = this.isChosenAnswerCorrect(questionDoc, chosenAnswersBaseQuiz)
                    if (correct) {
                        correctCount += 1
                    }
                    count += 1
                }
            }
            return false
        })

        if (correctCount === 0 || count === 0) {
            return '0.00'
        }
        return ((correctCount / count) * 100).toFixed(2)
    }

    getStats = () => {
        const quiz = this.props.quiz
        if (!quiz) {
            return
        }
        if (quiz.quiz.docs.length === 0) {
            return
        }
        if (!(this.props &&
            this.props.studentDict)) {
            return
        }
        console.log('quiz', quiz)
        /*if (this.state.statsRetrieved) {
            return
        }
    
        this.setState({ statsRetrieved: true })*/

        let stats = {
            questionDataDict: {},
            questionData: [],
            questionsMostIncorrect: [],
            questionsMostCorrect: [],
            studentsCompleted: 0,
        }

        Object.keys(quiz.studentGrades).map((studentId) => {
            if (!this.props.studentDict.hasOwnProperty(studentId)) {
                return false
            }
            if (!quiz.studentGrades.hasOwnProperty(studentId)) {
                return false
            }
            const student = this.props.studentDict[studentId]
            const studentGrade = quiz.studentGrades[studentId]
            let assessmentCompleted = studentGrade.graded
            let highestDOKLevel = 1
            if (assessmentCompleted) {
                stats.studentsCompleted += 1
                const chosenAnswersBaseQuiz = studentGrade.chosenAnswersBaseQuiz
                for (let i = 0; i < quiz.quiz.docs.length; i++) {
                    const questionDoc = quiz.quiz.docs[i]
                    const dokLevel = questionDoc.metadata.dokLevel
                    const uuid = questionDoc.metadata.uuid
                    if (!chosenAnswersBaseQuiz.hasOwnProperty(uuid)) {
                        continue
                    }
                    const correct = this.isChosenAnswerCorrect(questionDoc, chosenAnswersBaseQuiz)
                    if (correct) {
                        const dokLevelNumber = parseInt(dokLevel, 10)
                        if (dokLevelNumber > highestDOKLevel) {
                            highestDOKLevel = dokLevelNumber
                        }
                    }

                    if (!stats.questionDataDict.hasOwnProperty(i)) {
                        stats.questionDataDict[i] = {
                            correct: 0,
                            incorrect: 0,
                            total: 0,
                        }
                    }
                    if (correct) {
                        stats.questionDataDict[i].correct += 1
                    } else {
                        stats.questionDataDict[i].incorrect += 1
                    }
                    stats.questionDataDict[i].total += 1
                }
            }
        })

        for (let i = 0; i < quiz.quiz.docs.length; i++) {
            if (!stats.questionDataDict.hasOwnProperty(i)) {
                stats.questionData.push({
                    questionIndex: i,
                    correct: 0,
                    incorrect: 0,
                    total: 0,
                })
                continue
            }
            const questionData = stats.questionDataDict[i]
            stats.questionData.push({
                questionIndex: i,
                correct: questionData.correct,
                incorrect: questionData.incorrect,
                total: questionData.total,
            })
        }

        stats.questionsMostIncorrect = Object.assign([], stats.questionData).sort((a, b) => {
            return b.incorrect - a.incorrect
        })
        stats.questionsMostCorrect = Object.assign([], stats.questionData).sort((a, b) => {
            return b.correct - a.correct
        })

        this.setState({
            stats: stats,
            statsCompleted: true,
        })
    }

    getStudentView = (studentId, DOKLevel) => {
        if (!(this.props.quiz &&
            this.props.studentDict &&
            studentId &&
            this.props.studentDict.hasOwnProperty(studentId))) {
            return false
        }
        const quiz = this.props.quiz
        const student = this.props.studentDict[studentId]
        if (!(student && quiz)) {
            return false
        }
        if (quiz.quiz.docs.length === 0) {
            return false
        }
        if (!quiz.studentGrades.hasOwnProperty(student.id)) {
            return false
        }
        const studentGrade = quiz.studentGrades[student.id]
        let assessmentCompleted = studentGrade.graded

        if (this.props.hasOwnProperty('onlyShowCompleted') &&
            this.props.onlyShowCompleted &&
            !assessmentCompleted) {
            return false
        }

        return <tr key={'tr-' + student.id} className='page-break-inside-avoid'>
            <td>
                <PersonAvatar
                    person={student}
                    size={'large'}
                    containerClassName="font-20"
                    avatarClassName="font-20 font-bold mr-2"
                    personClassName="font-black font-20 ellipsis"
                />
            </td>
            <td>DOK Level {DOKLevel}</td>
            <td>
                {assessmentCompleted ? (quiz.studentGrades[student.id].finalGrade.grade * 100).toFixed(2) + '%' : '-'}
            </td>
        </tr>
    }

    sortDOKStudents = (studentIds) => {
        if (!this.props.quiz) {
            return studentIds
        }

        return studentIds.sort((a, b) => {
            if (!this.props.quiz.studentGrades.hasOwnProperty(a) ||
                !this.props.quiz.studentGrades.hasOwnProperty(b)) {
                return 0
            }

            const studentAGrade = this.props.quiz.studentGrades[a].finalGrade.grade
            const studentBGrade = this.props.quiz.studentGrades[b].finalGrade.grade
            return studentAGrade - studentBGrade
        })
    }

    render() {
        let dok1Students = []
        let dok2Students = []
        let dok3Students = []
        if (this.props.quiz) {
            dok1Students = this.sortDOKStudents(this.getStudentsInDOKLevel(this.props.quiz, 1))
            dok2Students = this.sortDOKStudents(this.getStudentsInDOKLevel(this.props.quiz, 2))
            dok3Students = this.sortDOKStudents(this.getStudentsInDOKLevel(this.props.quiz, 3))
        }
        return (
            <div className='relative'>
                {this.props.quiz ?
                    <div>
                        <div className='font-20 mb-2 page-break-inside-avoid'>
                            Class Average: {this.getAverageForQuiz(this.props.quiz)}%
                        </div>
                        <table className='w-100 font-20 stats-table'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>DOK Level</th>
                                    <th>Grade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dok1Students.map((studentId, studentIndex) => {
                                    return this.getStudentView(studentId, 1)
                                })}

                                {dok2Students.map((studentId, studentIndex) => {
                                    return this.getStudentView(studentId, 2)
                                })}

                                {dok3Students.map((studentId, studentIndex) => {
                                    return this.getStudentView(studentId, 3)
                                })}
                            </tbody>
                        </table>


                    </div>
                    : ''}
            </div>
        )
    }
}

export default DOKStandardBreakdownPrint
