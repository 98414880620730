import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import CustomFooter from '../../login/CustomFooter'
//import SubjectHistogram from '../../admin/SubjectHistogram'
//import SupportPieChartTiers from '../../admin/SupportPieChartTiers'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import Empty from '../../customcomponents/Empty'
//import { getIDFromURL } from '../.././Util'
import { chunkArray, getAssessmentDisplayName, getQueryStringParam, getColorGradient } from '../.././Util'
//import { db } from '../../firebase/Firebase'
//import ColType from '../.././Types'
import { Layout, Icon, Menu, Progress, Select, Button } from 'antd'
//import PacingGuideAssessment from './PacingGuideAssessment'
import DocumentDelegator from '../../edviz/EdvizDelegator'
import PersonAvatar from '../../customcomponents/PersonAvatar'
import DOKStandardBreakdown from '../../admin/DOKStandardBreakdown'
import ReactToPrint from "react-to-print"
import moment from 'moment'
const { Content } = Layout
//var moment = require('moment')

class PacingGuideTeacherAssessment extends Component {
    state = {
        assessmentId: '',
        selectedKeys: ['results'],
        stats: {
            questionDataDict: {},
            questionData: [],
            questionsMostIncorrect: [],
            questionsMostCorrect: [],
            studentsCompleted: 0,
            studentsTotal: 0,
        },
        //statsRetrieved: false,
        statsCompleted: false,
        selectedStudentId: '',
    }

    componentDidMount() {
        let assessmentId = getQueryStringParam('assessment')
        this.setState({ assessmentId }, () => {
            this.getStats()
        })
    }

    componentWillReceiveProps(nextProps) {
        this.getStats()
    }

    areChosenAnswersCorrect = (question, chosenAnswer) => {
        const questionAnswers = question.components[0].answers
        for (let answer of chosenAnswer.answer) {
            if (!questionAnswers[answer].correct) {
                return false
            }
        }
        return true
    }

    isChosenAnswerCorrect = (questionDoc, chosenAnswersBaseQuiz) => {
        const uuid = questionDoc.metadata.uuid
        if (!chosenAnswersBaseQuiz.hasOwnProperty(uuid)) {
            return false
        }
        const chosenAnswerBaseQuestionDocId = chosenAnswersBaseQuiz[uuid]
        for (let i = 0; i < questionDoc.components[0].answers.length; i++) {
            const answer = questionDoc.components[0].answers[i]
            if (answer.correct && answer.id === chosenAnswerBaseQuestionDocId) {
                return true
            }
        }
        return false
    }

    getChosenAnswerDocId = (questionDoc, chosenAnswersBaseQuiz) => {
        const uuid = questionDoc.metadata.uuid
        if (!chosenAnswersBaseQuiz.hasOwnProperty(uuid)) {
            return -1
        }
        return chosenAnswersBaseQuiz[uuid]
    }

    numStudentsInDOKLevel = (quiz, dokLevel) => {
        if (!(quiz && dokLevel)) {
            return -1
        }
        if (quiz.quiz.docs.length === 0) {
            return -1
        }
        let count = 0
        Object.keys(quiz.studentGrades).map((studentId) => {
            if (!quiz.studentGrades.hasOwnProperty(studentId)) {
                return false
            }
            const studentGrade = quiz.studentGrades[studentId]
            let assessmentCompleted = studentGrade.graded
            let highestDOKLevel = 1
            if (assessmentCompleted) {
                const finalGrade = studentGrade.finalGrade.grade * 100
                const chosenAnswersBaseQuiz = studentGrade.chosenAnswersBaseQuiz
                for (let i = 0; i < quiz.quiz.docs.length; i++) {
                    const questionDoc = quiz.quiz.docs[i]
                    const dokLevel = questionDoc.metadata.dokLevel
                    const uuid = questionDoc.metadata.uuid
                    if (!chosenAnswersBaseQuiz.hasOwnProperty(uuid)) {
                        continue
                    }
                    const correct = this.isChosenAnswerCorrect(questionDoc, chosenAnswersBaseQuiz)
                    if (correct) {
                        const dokLevelNumber = parseInt(dokLevel, 10)
                        if (dokLevelNumber > highestDOKLevel) {
                            if (dokLevelNumber === 2 && finalGrade >= 40) {
                                highestDOKLevel = dokLevelNumber
                            } else if (dokLevelNumber === 3 && finalGrade >= 60) {
                                highestDOKLevel = dokLevelNumber
                            }
                        }
                    }
                }
            }
            if (highestDOKLevel !== dokLevel) {
                return false
            }
            if (highestDOKLevel === dokLevel && assessmentCompleted) {
                count += 1
            }
            // dokLevel1 if not completed
            if (!assessmentCompleted && dokLevel === 1) {
                count += 1
            }
            return false
        })

        return count
    }

    getStats = () => {
        const quiz = this.getAssessment()
        if (!quiz) {
            return
        }
        if (quiz.quiz.docs.length === 0) {
            return
        }
        if (!(this.props.teacherBaseProps &&
            this.props.teacherBaseProps.studentDict)) {
            return
        }
        //console.log('quiz', quiz)
        /*if (this.state.statsRetrieved) {
            return
        }

        this.setState({ statsRetrieved: true })*/

        let stats = {
            questionDataDict: {},
            questionData: [],
            questionsMostIncorrect: [],
            questionsMostCorrect: [],
            studentsCompleted: 0,
            studentsTotal: Object.keys(quiz.studentGrades).length,
        }

        Object.keys(quiz.studentGrades).map((studentId) => {
            if (!this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId)) {
                return false
            }
            if (!quiz.studentGrades.hasOwnProperty(studentId)) {
                return false
            }
            const student = this.props.teacherBaseProps.studentDict[studentId]
            const studentGrade = quiz.studentGrades[studentId]
            let assessmentCompleted = studentGrade.graded
            let highestDOKLevel = 1
            if (assessmentCompleted) {
                stats.studentsCompleted += 1
                const chosenAnswersBaseQuiz = studentGrade.chosenAnswersBaseQuiz
                for (let i = 0; i < quiz.quiz.docs.length; i++) {
                    const questionDoc = quiz.quiz.docs[i]
                    const dokLevel = questionDoc.metadata.dokLevel
                    const uuid = questionDoc.metadata.uuid
                    if (!chosenAnswersBaseQuiz.hasOwnProperty(uuid)) {
                        continue
                    }
                    const correct = this.isChosenAnswerCorrect(questionDoc, chosenAnswersBaseQuiz)
                    const chosenAnswerDocId = this.getChosenAnswerDocId(questionDoc, chosenAnswersBaseQuiz)
                    if (correct) {
                        const dokLevelNumber = parseInt(dokLevel, 10)
                        if (dokLevelNumber > highestDOKLevel) {
                            highestDOKLevel = dokLevelNumber
                        }
                    }

                    if (!stats.questionDataDict.hasOwnProperty(i)) {
                        stats.questionDataDict[i] = {
                            correct: 0,
                            incorrect: 0,
                            total: 0,
                            chosen_answers: {
                                0: 0,
                                1: 0,
                                2: 0,
                                3: 0,
                            },
                        }
                    }
                    if (correct) {
                        stats.questionDataDict[i].correct += 1
                    } else {
                        stats.questionDataDict[i].incorrect += 1
                    }

                    if (chosenAnswerDocId !== -1) {
                        if (stats.questionDataDict[i].chosen_answers.hasOwnProperty(chosenAnswerDocId)) {
                            stats.questionDataDict[i].chosen_answers[chosenAnswerDocId] += 1
                        }
                    }
                    stats.questionDataDict[i].total += 1
                }
            }
        })

        for (let i = 0; i < quiz.quiz.docs.length; i++) {
            if (!stats.questionDataDict.hasOwnProperty(i)) {
                stats.questionData.push({
                    questionIndex: i,
                    correct: 0,
                    incorrect: 0,
                    total: 0,
                })
                continue
            }
            const questionData = stats.questionDataDict[i]
            stats.questionData.push({
                questionIndex: i,
                correct: questionData.correct,
                incorrect: questionData.incorrect,
                total: questionData.total,
            })
        }

        stats.questionsMostIncorrect = Object.assign([], stats.questionData).sort((a, b) => {
            return b.incorrect - a.incorrect
        })
        stats.questionsMostCorrect = Object.assign([], stats.questionData).sort((a, b) => {
            return b.correct - a.correct
        })

        this.setState({
            stats: stats,
            statsCompleted: true,
        })
    }

    resultsForDOKLevel = (student, quiz, dokLevel) => {
        if (!(student && quiz && dokLevel)) {
            return false
        }
        if (quiz.quiz.docs.length === 0) {
            return false
        }
        if (!quiz.studentGrades.hasOwnProperty(student.id)) {
            return false
        }
        const studentGrade = quiz.studentGrades[student.id]
        let assessmentCompleted = studentGrade.graded
        let highestDOKLevel = 1
        if (assessmentCompleted) {
            const finalGrade = studentGrade.finalGrade.grade * 100
            const chosenAnswersBaseQuiz = studentGrade.chosenAnswersBaseQuiz
            for (let i = 0; i < quiz.quiz.docs.length; i++) {
                const questionDoc = quiz.quiz.docs[i]
                const dokLevel = questionDoc.metadata.dokLevel
                const uuid = questionDoc.metadata.uuid
                if (!chosenAnswersBaseQuiz.hasOwnProperty(uuid)) {
                    continue
                }
                const correct = this.isChosenAnswerCorrect(questionDoc, chosenAnswersBaseQuiz)
                if (correct) {
                    const dokLevelNumber = parseInt(dokLevel, 10)
                    if (dokLevelNumber > highestDOKLevel) {
                        if (dokLevelNumber === 2 && finalGrade >= 40) {
                            highestDOKLevel = dokLevelNumber
                        } else if (dokLevelNumber === 3 && finalGrade >= 60) {
                            highestDOKLevel = dokLevelNumber
                        }
                    }
                }
            }
        }
        if (highestDOKLevel !== dokLevel) {
            return false
        }

        return <tr key={'tr-' + student.id}>
            <td>
                <PersonAvatar
                    person={student}
                    size={'large'}
                    containerClassName="font-20"
                    avatarClassName="font-20 font-bold mr-2"
                    personClassName="font-black font-24 ellipsis"
                    personStyle={{ maxWidth: '220px' }}
                />
            </td>
            <td>
                {assessmentCompleted ?
                    <div className='text-success font-bold'>
                        Completed
                    </div> :
                    <div className='text-blue font-bold'>In progress</div>
                }
            </td>
            <td>
                <div className='flex'>
                    {quiz.quiz.docs.map((question, index) => {
                        let allCorrect = false
                        if (assessmentCompleted) {
                            let studentGrade = quiz.studentGrades[student.id]
                            if (studentGrade.hasOwnProperty('chosenAnswersBaseQuiz')) {
                                allCorrect = this.isChosenAnswerCorrect(question, studentGrade.chosenAnswersBaseQuiz)
                            }
                        }
                        let borderClasses = ' border-right border-top border-bottom'
                        if (index === 0) {
                            borderClasses += ' border-left br-tl-4 br-bl-4'
                        } else if (index === quiz.quiz.docs.length - 1) {
                            borderClasses += ' br-tr-4 br-br-4'
                        }
                        return <div className={'p-1' + borderClasses} key={'assessment-' + student.id + index}>
                            {assessmentCompleted ?
                                allCorrect ?
                                    <Icon type='check' className='text-success' /> :
                                    <Icon type='close' className='text-danger' /> :
                                <div className='text-center' style={{ width: '20px' }}>-</div>}
                        </div>
                    })}
                </div>
            </td>
            <td>{assessmentCompleted ? (quiz.studentGrades[student.id].finalGrade.grade * 100) + '%' : '-'}</td>
        </tr>
    }

    getAssessment = () => {
        let assessment = null
        if (this.props.assessmentBaseProps &&
            this.state.assessmentId &&
            this.props.assessmentBaseProps.assessmentsDict &&
            this.props.assessmentBaseProps.assessmentsDict.hasOwnProperty(this.state.assessmentId) &&
            this.props.assessmentBaseProps.assessmentsDict[this.state.assessmentId]) {
            assessment = this.props.assessmentBaseProps.assessmentsDict[this.state.assessmentId]
        } else if (this.props.assessmentBaseProps &&
            this.state.assessmentId &&
            this.props.assessmentBaseProps.deletedAssessmentsDict &&
            this.props.assessmentBaseProps.deletedAssessmentsDict.hasOwnProperty(this.state.assessmentId) &&
            this.props.assessmentBaseProps.deletedAssessmentsDict[this.state.assessmentId]) {
            assessment = this.props.assessmentBaseProps.deletedAssessmentsDict[this.state.assessmentId]
        }
        return assessment
    }

    render() {
        let assessment = this.getAssessment()

        let assessmentTitle = ''
        if (assessment) {
            assessmentTitle = getAssessmentDisplayName(assessment.quiz)
            if (assessment.quiz.hasOwnProperty('assessmentTitle')) {
                assessmentTitle = assessment.quiz.assessmentTitle
            } else if (assessment.quiz.assessmentCategory === 'quiz') {
                assessmentTitle = assessment.quiz.standard.standard + ' Quiz'
            } else if (assessment.quiz.assessmentCategory === 'common_assessment') {
                if (assessment.quiz.hasOwnProperty('standards') && assessment.quiz.standards && assessment.quiz.standards.length > 0) {
                    assessmentTitle = assessment.quiz.standards[0].standardCategory + ' ' + assessmentTitle
                }
            } else if (assessment.quiz.assessmentCategory === 'benchmark') {
                if (assessment.quiz.hasOwnProperty('standards') && assessment.quiz.standards && assessment.quiz.standards.length > 0) {
                    assessmentTitle = assessment.quiz.standards[0].standardCategory + ' ' + assessmentTitle
                }
            }
        }

        //console.log(this.state, assessment)

        return (
            <div>
                <Layout className="content">
                    <Layout className="mt-0">
                        <Layout.Sider
                            width={270}
                            style={{
                                overflow: 'auto', overflowX: 'hidden', height: 'calc(100% - 82px)',
                                position: 'fixed',
                                left: 0, marginTop: '82px', borderRight: '1px solid #f1f1f1',
                                background: '#fff', borderTop: '1px solid #f1f1f1',
                                zIndex: 10,
                            }}>
                            <Menu className='font-20 menu-vlg-2 pt-4 border-right-none'
                                selectedKeys={this.state.selectedKeys}
                                onSelect={(e) => {
                                    this.setState({
                                        selectedKeys: [e.key]
                                    }, () => {
                                        window.scrollTo(0, 0)
                                    })
                                }}
                            >
                                <Menu.Item key="results">
                                    <div className="font-20 flex-v-center w-100">
                                        <Icon type="solution" className="font-24" />
                                        <span>Class Report</span>
                                    </div>
                                </Menu.Item>
                                <Menu.Item key="analytics">
                                    <div className="font-20 flex-v-center w-100">
                                        <Icon type="bar-chart" className="font-24" />
                                        <span>
                                            Question Analytics
                                        </span>
                                    </div>
                                </Menu.Item>
                                <Menu.Item key="questions">
                                    <div className="font-20 flex-v-center w-100">
                                        <Icon type="ordered-list" className="font-24" />
                                        <span>
                                            Question List
                                        </span>
                                    </div>
                                </Menu.Item>
                                <Menu.Item key="print-reports">
                                    <div className="font-20 flex-v-center w-100">
                                        <Icon type="printer" className="font-24" />
                                        <span>
                                            Print Student Report
                                        </span>
                                    </div>
                                </Menu.Item>
                            </Menu>
                        </Layout.Sider>
                        <Layout.Content style={{
                            marginLeft: 269, marginTop: "80px", overflowX: 'initial'
                        }}
                            className='pt-1'
                        >

                            <div className="mt-4 pt-1 pb-2">
                                <div>
                                    {this.props.teacherBaseProps &&
                                        this.props.teacherBaseProps.teacher &&
                                        assessment ?
                                        <div className='mb-3 font-16 sticky-left sticky-left-more display-table'>
                                            <div className='flex flex-v-center'>
                                                <Link to={'/teacher/quizzes/all/' + this.props.teacherBaseProps.teacher.id} scroll={false}>
                                                    <div className='font-16'>Assessments</div>
                                                </Link>
                                                <Icon type='right' className='ml-1 mr-1 font-16' />
                                                <div>{assessmentTitle + (this.state.selectedKeys.length > 0 ?
                                                    this.state.selectedKeys[0] === 'results' ?
                                                        ' - Class Report' :
                                                        this.state.selectedKeys[0] === 'questions' ?
                                                            ' - Question List' :
                                                            this.state.selectedKeys[0] === 'analytics' ?
                                                                ' - Question Analytics' : '' : '')}</div>
                                            </div>
                                        </div>
                                        : ''}
                                </div>
                                {this.state.selectedKeys.length > 0 &&
                                    this.state.selectedKeys[0] === 'results' ?
                                    <div>
                                        {assessment &&
                                            this.props.teacherBaseProps.students &&
                                            this.props.teacherBaseProps.studentDict ?
                                            <DOKStandardBreakdown
                                                quiz={assessment}
                                                students={this.props.teacherBaseProps.students}
                                                studentDict={this.props.teacherBaseProps.studentDict}
                                                onlyShowCompleted={false}
                                                showProgress={true}
                                            />
                                            : ''}
                                    </div>

                                    : ''}

                                {this.state.selectedKeys.length > 0 &&
                                    this.state.selectedKeys[0] === 'questions' ?
                                    <div className='pl-2 pr-2'>
                                        <div className='relative mb-2'>
                                            <img src="/home-banner2.png" alt="home" className='w-100 br-20'
                                                style={{ objectFit: 'cover', maxHeight: '200px' }} />
                                            <div className='absolute-tl w-100 h-100 p-4 flex flex-v-center'>
                                                <div>
                                                    <div className='font-40 font-bold font-fff'>{assessmentTitle + (this.state.selectedKeys.length > 0 ?
                                                        this.state.selectedKeys[0] === 'results' ?
                                                            ' - Class Report' :
                                                            this.state.selectedKeys[0] === 'questions' ?
                                                                ' - Question List' :
                                                                this.state.selectedKeys[0] === 'analytics' ?
                                                                    ' - Question Analytics' : '' : '')}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-2 pr-3 question-container'>
                                            {assessment && assessment.quiz.docs.map((lessonPlanItem, idx) => {
                                                return <div className='font-black font-20 border ant-shadow br-4 p-3 mb-50' key={'assessment-idx-' + idx}>
                                                    <div className='font-24'>Question {idx + 1}</div>
                                                    <DocumentDelegator
                                                        document={lessonPlanItem}
                                                    />
                                                    {lessonPlanItem ?
                                                        <div className='mt-4'>
                                                            <div className='font-16 font-bold'>
                                                                Question is for standard: {lessonPlanItem.metadata.standard.standard}
                                                            </div>
                                                            {lessonPlanItem.metadata.standard.hasOwnProperty('isPriority') &&
                                                                lessonPlanItem.metadata.standard.isPriority ?
                                                                <div className='font-16'>*Priority Standard</div>
                                                                : ''}
                                                            <div className='mt-1 font-16'>{lessonPlanItem.metadata.standard.title}</div>
                                                        </div>
                                                        : ''}
                                                </div>
                                            })}
                                        </div>
                                    </div> : ''}

                                {this.state.selectedKeys.length > 0 &&
                                    this.state.selectedKeys[0] === 'analytics' ?
                                    <div className='pl-2 pr-2'>
                                        <div className='relative mb-2'>
                                            <img src="/home-banner2.png" alt="home" className='w-100 br-20'
                                                style={{ objectFit: 'cover', maxHeight: '200px' }} />
                                            <div className='absolute-tl w-100 h-100 p-4 flex flex-v-center'>
                                                <div>
                                                    <div className='font-40 font-bold font-fff'>{assessmentTitle + (this.state.selectedKeys.length > 0 ?
                                                        this.state.selectedKeys[0] === 'results' ?
                                                            ' - Class Report' :
                                                            this.state.selectedKeys[0] === 'questions' ?
                                                                ' - Question List' :
                                                                this.state.selectedKeys[0] === 'analytics' ?
                                                                    ' - Question Analytics' : '' : '')}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <div className='font-24 text-center'>Students completed: {this.state.stats.studentsCompleted} / {this.state.stats.studentsTotal} {'(' + (this.state.stats.studentsTotal > 0 ? ((this.state.stats.studentsCompleted / this.state.stats.studentsTotal) * 100).toFixed(2) : '-') + '%)'}</div>
                                            {assessment && this.state.statsCompleted ?
                                                <div>
                                                    <div className='mb-2 pb-05 pt-2 background-fff'>
                                                        {chunkArray(assessment.quiz.docs, 10).map((chunk, chunkIndex) => {
                                                            return <div className='flex flex-v-center flex-h-center w-100 pt-1'>
                                                                {chunk.map((lessonPlanItem, questionIndex) => {
                                                                    const oneDimIndex = (chunkIndex * 10) + questionIndex
                                                                    let borderClasses = ' border-right border-top border-bottom'
                                                                    if (questionIndex === 0) {
                                                                        borderClasses += ' border-left br-tl-4 br-bl-4'
                                                                    } else if (questionIndex === assessment.quiz.docs.length - 1) {
                                                                        borderClasses += ' br-tr-4 br-br-4'
                                                                    }
                                                                    if (!this.state.stats.questionDataDict.hasOwnProperty(oneDimIndex)) {
                                                                        return false
                                                                    }
                                                                    const questionData = this.state.stats.questionDataDict[oneDimIndex]
                                                                    const correct = questionData.correct
                                                                    const incorrect = questionData.incorrect
                                                                    const total = questionData.total
                                                                    const correctPctNumber = (total > 0 ? ((correct / total) * 100) : 0)
                                                                    const correctPct = (total > 0 ? ((correct / total) * 100).toFixed(2) : 0)
                                                                    let background = '#fff'
                                                                    if (correctPctNumber < 30) {
                                                                        background = '#ffccc7'
                                                                    } else if (correctPctNumber < 60) {
                                                                        background = '#ffe7ba'
                                                                    } else if (correctPctNumber) {
                                                                        background = '#d9f7be'
                                                                    }
                                                                    return <div key={'assessment-total-' + oneDimIndex}>
                                                                        <div className='font-16 text-center mb-05'>{'Q' + (oneDimIndex + 1)}</div>
                                                                        <div className={'p-1' + borderClasses} style={{ background: getColorGradient(correctPctNumber) }}>
                                                                            <div className='text-center font-16' style={{ width: '65px' }}>
                                                                                {total > 0 ? correctPct + '%' : '-'}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        })}
                                                    </div>

                                                    <div className='question-container'>
                                                        {this.state.stats.studentsCompleted === 0 ?
                                                            <div className='font-24 text-center'>
                                                                No students have completed this assessment yet.
                                                            </div> : ''}
                                                        {this.state.stats.questionData.map((questionStats, idx) => {
                                                            const questionIdx = questionStats.questionIndex
                                                            const lessonPlanItem = assessment.quiz.docs[questionIdx]
                                                            if (!this.state.stats.questionDataDict.hasOwnProperty(questionIdx)) {
                                                                return false
                                                            }
                                                            const questionData = this.state.stats.questionDataDict[questionIdx]
                                                            //console.log(questionData)
                                                            const correct = questionData.correct
                                                            const incorrect = questionData.incorrect
                                                            const total = questionData.total
                                                            const correctPctNumber = (total > 0 ? ((correct / total) * 100) : 0)
                                                            const correctPct = (total > 0 ? parseFloat(((correct / total) * 100).toFixed(2)) : 0)
                                                            const incorrectPct = (total > 0 ? parseFloat(((incorrect / total) * 100).toFixed(2)) : 0)
                                                            let progressBarClassName = ''
                                                            if (correctPctNumber < 30) {
                                                                progressBarClassName = 'progress-light-red'
                                                            } else if (correctPctNumber < 60) {
                                                                progressBarClassName = 'progress-light-yellow'
                                                            } else if (correctPctNumber) {
                                                                progressBarClassName = 'progress-light-green'
                                                            }
                                                            return <div key={'most-incorrect-' + idx} className='font-black font-20 border ant-shadow br-4 p-3 mb-50'>
                                                                <div className='font-24 font-bold mb-2'>Question {questionIdx + 1}</div>

                                                                <div className='font-20'>{correct} / {total} ({correctPct}%) students answered this question correctly</div>
                                                                <div className='pr-12px'>
                                                                    <Progress size='large'
                                                                        percent={correctPct}
                                                                        className={progressBarClassName} />
                                                                </div>
                                                                <div className='mt-4'>
                                                                    <DocumentDelegator
                                                                        document={lessonPlanItem}
                                                                        additionalData={{ questionStats: questionData }}
                                                                    />
                                                                </div>
                                                                {lessonPlanItem ?
                                                                    <div className='mt-4'>
                                                                        <div className='font-16 font-bold'>
                                                                            This question is for the standard: {lessonPlanItem.metadata.standard.standard}
                                                                        </div>
                                                                        {lessonPlanItem.metadata.standard.hasOwnProperty('isPriority') &&
                                                                            lessonPlanItem.metadata.standard.isPriority ?
                                                                            <div className='font-16'>*Priority Standard</div>
                                                                            : ''}
                                                                        <div className='mt-1 font-16'>{lessonPlanItem.metadata.standard.title}</div>
                                                                    </div>
                                                                    : ''}
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : ''}
                                        </div>
                                    </div> : ''}

                                {this.state.selectedKeys.length > 0 &&
                                    this.state.selectedKeys[0] === 'print-reports' ?
                                    <div className='pl-2 pr-2'>
                                        <div className='relative mb-2'>
                                            <img src="/home-banner2.png" alt="home" className='w-100 br-20'
                                                style={{ objectFit: 'cover', maxHeight: '200px' }} />
                                            <div className='absolute-tl w-100 h-100 p-4 flex flex-v-center'>
                                                <div>
                                                    <div className='font-40 font-bold font-fff'>{assessmentTitle + ' - Print Student Report'}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-2 pr-3 question-container'>
                                            <div className='flex flex-v-center mb-3 w-100'>
                                                <div>
                                                    <div className='font-16 mb-05'>Select a student:</div>
                                                    <Select size='large' style={{ width: '300px' }}
                                                        value={this.state.selectedStudentId ? this.state.selectedStudentId : undefined}
                                                        placeholder="Select student..." onChange={(e) => {
                                                            this.setState({
                                                                selectedStudentId: e,
                                                            })
                                                        }}>
                                                        {assessment && Object.keys(assessment.studentGrades).map((studentId, idx) => {
                                                            if (!(this.props.teacherBaseProps &&
                                                                this.props.teacherBaseProps.studentDict)) {
                                                                return false
                                                            }
                                                            if (!this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId)) {
                                                                return false
                                                            }
                                                            const studentGrade = assessment.studentGrades[studentId]
                                                            if (!studentGrade.graded) {
                                                                return false
                                                            }
                                                            const student = this.props.teacherBaseProps.studentDict[studentId]
                                                            return <Select.Option key={'student-' + idx} value={studentId}>
                                                                <PersonAvatar
                                                                    person={student}
                                                                    size={'large'}
                                                                    containerClassName="font-20"
                                                                    avatarClassName="font-20 font-bold mr-2"
                                                                    personClassName="font-black font-20 ellipsis"
                                                                />
                                                            </Select.Option>
                                                        })}
                                                    </Select>
                                                </div>
                                                <ReactToPrint
                                                    trigger={() =>
                                                        <Button
                                                            size={'large'}
                                                            type='primary'
                                                            className="btn-vl ml-auto"
                                                            disabled={!this.state.selectedStudentId}
                                                        >
                                                            <Icon type="printer" />
                                                            Print
                                                        </Button>
                                                    }
                                                    content={() => this.componentRef}
                                                />
                                            </div>
                                            {!this.state.selectedStudentId ?
                                                <div className='mt-50 font-20 p-3 br-15 border flex w-100'>
                                                    <div>
                                                        Select a student above to get a printable student report.<br />Only students who have completed this assessment can be selected.
                                                    </div>
                                                </div>
                                                : ''}
                                            <div ref={el => (this.componentRef = el)}>
                                                {assessment &&
                                                    this.state.selectedStudentId &&
                                                    this.props.teacherBaseProps &&
                                                    this.props.teacherBaseProps.studentDict &&
                                                    this.props.teacherBaseProps.studentDict.hasOwnProperty(this.state.selectedStudentId) &&
                                                    this.props.teacherBaseProps.studentDict[this.state.selectedStudentId] ?
                                                    <div className='mb-2'>
                                                        <div className='font-30 font-bold'>
                                                            {this.props.teacherBaseProps.studentDict[this.state.selectedStudentId].firstName}'s Assessment Report
                                                        </div>
                                                        <div className='font-24'>
                                                            {assessment.hasOwnProperty('timeStamp') &&
                                                                assessment.timeStamp &&
                                                                assessment.timeStamp.hasOwnProperty('seconds') &&
                                                                assessment.timeStamp.seconds ?
                                                                moment.utc(assessment.timeStamp.seconds * 1000).format('MM/DD/YYYY') : ''}
                                                        </div>
                                                    </div>
                                                    : ''}

                                                {assessment && assessment.quiz.docs.map((lessonPlanItem, idx) => {
                                                    if (!this.state.selectedStudentId) {
                                                        return false
                                                    }
                                                    if (!(this.props.teacherBaseProps &&
                                                        this.props.teacherBaseProps.studentDict)) {
                                                        return false
                                                    }
                                                    if (!this.props.teacherBaseProps.studentDict.hasOwnProperty(this.state.selectedStudentId)) {
                                                        return false
                                                    }
                                                    const student = this.props.teacherBaseProps.studentDict[this.state.selectedStudentId]
                                                    let studentGrade = assessment.studentGrades[this.state.selectedStudentId]
                                                    let assessmentCompleted = studentGrade.graded
                                                    if (!assessmentCompleted) {
                                                        return <div className='mt-50 text-center font-20'>This student has not completed this assessment yet.</div>
                                                    }
                                                    let chosenAnswersBaseQuiz = studentGrade.chosenAnswersBaseQuiz
                                                    let chosenId = chosenAnswersBaseQuiz[lessonPlanItem.metadata.uuid]
                                                    let pageBreakInsideAvoid = idx > 0 ? 'page-break-inside-avoid ' : ''
                                                    return <div className={pageBreakInsideAvoid + 'font-black font-20 border ant-shadow br-4 p-3 mb-50 print-remove-padding print-remove-border print-remove-shadow'} key={'assessment-idx-' + idx}>
                                                        <div className='font-24 print-mb-1'>Question {idx + 1}</div>
                                                        <DocumentDelegator
                                                            document={{
                                                                assessment: assessment,
                                                                components: lessonPlanItem.components[0].question,
                                                                metadata: lessonPlanItem.metadata,
                                                            }}
                                                        />
                                                        <div className='mt-2'></div>
                                                        {lessonPlanItem.components[0].answers.map((choice, choiceIndex) => {
                                                            let choseThisAnswer = choice.id === chosenId
                                                            let correct = choice.correct
                                                            let choiceLetter = 'A'
                                                            const choiceIndexPlusOne = choiceIndex + 1
                                                            if (choiceIndexPlusOne === 2) {
                                                                choiceLetter = 'B'
                                                            } else if (choiceIndexPlusOne === 3) {
                                                                choiceLetter = 'C'
                                                            } else if (choiceIndexPlusOne === 4) {
                                                                choiceLetter = 'D'
                                                            }
                                                            return <div className='mb-1 font-20' key={'choice-' + (idx + 1) + '-' + choiceIndex}>
                                                                <div className={'p-2 border br-4' + (correct ?
                                                                    ' border-success bg-light-green' : '') + (choseThisAnswer && !correct ?
                                                                        ' border-error bg-light-red' : '')}>
                                                                    {choseThisAnswer ?
                                                                        <div className='font-20 font-bold mb-2'>{student.firstName} selected this answer:</div>
                                                                        : ''}
                                                                    <div className='flex w-100'>
                                                                        <div className='mr-2'>
                                                                            <div className='flex w-100 p-mb-0'>
                                                                                <span className='mr-05'>{choiceLetter}.</span>
                                                                                <DocumentDelegator
                                                                                    document={{
                                                                                        assessment: assessment.quiz.quiz,
                                                                                        components: choice.choice,
                                                                                        metadata: lessonPlanItem.metadata,
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='ml-auto'>
                                                                            {correct ?
                                                                                <Icon type='check' className='text-success' />
                                                                                : ''}
                                                                            {choseThisAnswer && !correct ?
                                                                                <Icon type='close' className='text-danger' />
                                                                                : ''}
                                                                        </div>
                                                                    </div>

                                                                    {correct || choseThisAnswer ?
                                                                        <div className='font-20 mt-2 pt-2 border-top'>
                                                                            <div className='font-bold'>{'Why this answer is ' + (correct ? ' correct' : 'incorrect') + ':'}</div>
                                                                            <div className='p-mb-0'>
                                                                                <DocumentDelegator
                                                                                    document={{
                                                                                        assessment: assessment.quiz.quiz,
                                                                                        components: Array.isArray(choice.reason) ? choice.reason : [choice.reason],
                                                                                        metadata: lessonPlanItem.metadata,
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        : ''}



                                                                </div>
                                                            </div>
                                                        })}
                                                        {lessonPlanItem ?
                                                            <div className='mt-4 page-break-inside-avoid'>
                                                                <div className='font-16 font-bold'>
                                                                    Question is for standard: {lessonPlanItem.metadata.standard.standard}
                                                                </div>
                                                                {lessonPlanItem.metadata.standard.hasOwnProperty('isPriority') &&
                                                                    lessonPlanItem.metadata.standard.isPriority ?
                                                                    <div className='font-16'>*Priority Standard</div>
                                                                    : ''}
                                                                <div className='mt-1 font-16'>{lessonPlanItem.metadata.standard.title}</div>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    </div> : ''}

                            </div>
                        </Layout.Content>
                    </Layout>
                </Layout>
                <div style={{ marginLeft: 270, }}>
                    <CustomFooter />
                </div>
            </div>
        )
    }
}

export default PacingGuideTeacherAssessment
