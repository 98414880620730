import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import ColType from '../Types'
//import PersonAvatar from '../customcomponents/PersonAvatar'
//import Empty from '../customcomponents/Empty'
import { flattenDoc, formatNumberWithCommas, getStateStandardNamesDict } from '.././Util'
import BoxPlot from './BoxPlot'
import LineChart from './LineChart'
import { Radio, Icon, Layout } from 'antd'
import Empty from '../customcomponents/Empty'
import PersonAvatar from '../customcomponents/PersonAvatar'
import DistrictStatsV3 from './DistrictStatsV3'
import CustomFooter from '../login/CustomFooter'
//import { Row } from 'antd'
//import { Select } from 'antd'
//var moment = require('moment')
const Content = Layout.Content

class AdminDistrictStats extends Component {
    state = {
    }

    render() {
        return <div>
            <div>
                <Layout className="content relative">
                    <Layout>
                        <Content className='layout-header-mt'>
                            <div>
                                <div>
                                    <div className="sub-menu-width-vlg m-lr-auto mt-3">
                                        <DistrictStatsV3 />
                                    </div>
                                </div>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </div>
            <CustomFooter />
        </div>
    }
}

export default AdminDistrictStats