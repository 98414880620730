import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import CustomFooter from '../../login/CustomFooter'
import { Layout, Row, Col } from 'antd'
import { isReadOnlyTeacherId } from '../../Util'
const Content = Layout.Content

class TeacherRecords extends Component {
    state = {

    }

    componentDidMount() {
        document.title = 'Records - Dot It'
    }

    render() {
        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout>
                        <Content className="layout-content">
                            <div className="sub-menu-width m-lr-auto">
                                <img src="/records-banner.png" alt="banner" className='w-100 mb-3 br-20' />
                                {this.props.teacherBaseProps && this.props.teacherBaseProps.teacher ?
                                    <Row gutter={32}>
                                        <Col span={12}>

                                            <Link to={'/teacher/reports/' + this.props.teacherBaseProps.teacher.id}>
                                                <div className={"p-2 br-20 border background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center font-20 font-black"}>
                                                    <div className="br-4 overflow-hidden">
                                                        <img src="/record-icon1.png" alt="records icon" height="100" />
                                                    </div>
                                                    <h2 className="ml-3 font-24 font-bold">
                                                        View reports
                                                    </h2>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col span={12}>
                                            <Link
                                                to={'/teacher/archived/students/' + this.props.teacherBaseProps.teacher.id}
                                                disabled={isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id)}
                                            >
                                                <div
                                                    className={isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id) ?
                                                        "p-2 br-20 border background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center font-20 disabled-text cursor-not-allowed" :
                                                        "p-2 br-20 border background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center font-20 font-black"}
                                                >
                                                    <div className="br-4 overflow-hidden">
                                                        <img src="/record-icon2.png" alt="records icon" height="100" />
                                                    </div>
                                                    <h2 className="ml-3 font-24 font-bold">
                                                        View archived students
                                                    </h2>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col span={12}>
                                            <Link
                                                to={'/teacher/transfers/students/' + this.props.teacherBaseProps.teacher.id}
                                                disabled={isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id)}
                                            >
                                                <div
                                                    className={isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id) ?
                                                        "p-2 br-20 border background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center font-20 disabled-text cursor-not-allowed" :
                                                        "p-2 br-20 border background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center font-20 font-black"}
                                                >
                                                    <div className="br-4 overflow-hidden">
                                                        <img src="/record-icon3.png" alt="records icon" height="100" />
                                                    </div>
                                                    <h2 className="ml-3 font-24 font-bold">
                                                        Manage transferred students
                                                    </h2>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col span={12}>
                                            <Link
                                                to={'/teacher/deleted/students/' + this.props.teacherBaseProps.teacher.id}
                                                disabled={isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id)}
                                            >
                                                <div
                                                    className={isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id) ?
                                                        "p-2 br-20 border background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center font-20 disabled-text cursor-not-allowed" :
                                                        "p-2 br-20 border background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center font-20 font-black"}
                                                >
                                                    <div className="br-4 overflow-hidden">
                                                        <img src="/record-icon4.png" alt="records icon" height="100" />
                                                    </div>
                                                    <h2 className="ml-3 font-24 font-bold">
                                                        Recover deleted students
                                                    </h2>
                                                </div>
                                            </Link>
                                        </Col>
                                    </Row>
                                    : ''}
                            </div>
                        </Content>
                    </Layout>
                </Layout>
                <CustomFooter />
            </div>
        )
    }
}

export default TeacherRecords
