import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { firebase, db } from '../firebase/Firebase'
import AdminHeader from '../login/AdminHeader'
import AdminHome from './AdminHome'
import AdminSchool from './AdminSchool'
import AdminSchoolsSelect from './AdminSchoolsSelect'
import AdminTeacherView from './AdminTeacherView'
import AdminStudentView from './AdminStudentView'
import AdminTeacherPractice from './AdminTeacherPractice'
import AdminInstructionalFacilitatorsSchoolsChange from './AdminInstructionalFacilitatorsSchoolsChange'
import AdminChangeUserSchool from './AdminChangeUserSchool'
import AdminAssessmentAnalytics from './AdminAssessmentAnalytics'
import PacingGuide from '../teacher/pacingguide/PacingGuide'
//import VideoContainer from '../video/VideoContainer'
import {
  flattenDoc, getQueryStringParam, getURLSplitArray
} from '../Util'
import ColType from '../Types'
import { Layout } from 'antd'
import AssessmentAnalytics from './AssessmentAnalytics'
import AdminDistrictStats from './AdminDistrictStats'

const PacingGuideComponent = (props, state) => {
  return <PacingGuide teacherBaseProps={state} {...props} />
}

const AdminHomeComponent = (props, state) => {
  return <AdminHome adminBaseProps={state} {...props} />
}

const AdminSchoolsSelectComponent = (props, state) => {
  return <AdminSchoolsSelect adminBaseProps={state} {...props} />
}

const AdminSchoolComponent = (props, state) => {
  return <AdminSchool adminBaseProps={state} {...props} />
}

const AdminTeacherViewComponent = (props, state) => {
  return <AdminTeacherView adminBaseProps={state} {...props} />
}

const AdminStudentViewComponent = (props, state) => {
  return <AdminStudentView adminBaseProps={state} {...props} />
}

const AdminTeacherPracticeComponent = (props, state) => {
  return <AdminTeacherPractice adminBaseProps={state} {...props} />
}

const AdminInstructionalFacilitatorsSchoolsChangeComponent = (props, state) => {
  return <AdminInstructionalFacilitatorsSchoolsChange adminBaseProps={state} {...props} />
}

const AdminChangeUserSchoolComponent = (props, state) => {
  return <AdminChangeUserSchool adminBaseProps={state} {...props} />
}

const AdminAssessmentAnalyticsComponent = (props, state) => {
  return <AdminAssessmentAnalytics adminBaseProps={state} {...props} />
}

const AdminDistrictStatsComponent = (props, state) => {
  return <AdminDistrictStats adminBaseProps={state} {...props} />
}


class AdminBase extends Component {
  state = {
    adminId: '',
    admin: null,
    district: null,
    schools: null,
    schoolsDict: null,
    schoolTypes: null,
    districtSummary: null,
    districtStats: null,
    schoolStats: null,
    schoolStatsDict: null,
    districtSettings: null,
    allStatOptions: [],
    allStatOptionsDict: {},
    clearPrevStats: false,
    pathId: '',
    headerKey: '',
    authListener: null,
    districtListener: null,
    districtSettingsListener: null,
    chatNotificationListener: null,
    demoToken: null,
    user: null,
  }

  getSchoolTypeSortWeight = (schoolType) => {
    if (!schoolType) {
      return -1
    }
    if (schoolType === 'elementarySchool') {
      return 0
    } else if (schoolType === 'k8School') {
      return 1
    } else if (schoolType === 'middleSchool') {
      return 2
    } else if (schoolType === 'highSchool') {
      return 3
    }

    return 0
  }

  // Do fetch here
  componentDidMount() {
    var split = getURLSplitArray(window.location)
    var pathId = split[split.length - 2]
    var adminId = split[split.length - 1]
    let demoToken = getQueryStringParam('dt')

    this.setState({ demoToken: demoToken })

    //console.log('url ids', pathId, adminId)

    let authListener = firebase.auth().onAuthStateChanged((user) => {
      //console.log('user', user)
      if (!user) {
        if (this.state.user &&
          this.state.user.hasOwnProperty('districtId') &&
          this.state.user.districtId === 'vYTvkDwoLx19NkA6dJO8') {
          this.props.history.push(
            {
              pathname: '/beta/sign-in/'
            }
          )
        } else {
          // No user is signed in.
          this.props.history.push(
            {
              pathname: '/sign-in/'
            }
          )
        }
      }
      else {
        db.collection(ColType.users)
          .doc(user.uid)
          .get()
          .then((doc) => {
            var userObj = doc.data()
            userObj.id = doc.id
            userObj.uid = user.uid
            userObj.signedIn = true
            this.setState({ user: userObj }, () => {
              if (this.state.user &&
                this.state.user.hasOwnProperty('isLocked') &&
                this.state.user.isLocked) {
                this.props.history.push(
                  {
                    pathname: '/account-is-locked'
                  }
                )
              }
            })
          })
      }
    })
    this.setState({ authListener: authListener })

    db.collection(ColType.admin)
      .doc(adminId)
      .get()
      .then((doc) => {
        var admin = flattenDoc(doc)
        this.setState({
          pathId: pathId,
          adminId: adminId,
          admin: admin
        }, () => {
          db.collection(ColType.districtStats)
            .doc(admin.districtId)
            .get()
            .then((doc) => {
              if (doc.exists) {
                var districtStats = flattenDoc(doc)

                //console.log("district", districtStats)
                var allStatOptionsDict = this.state.allStatOptionsDict
                allStatOptionsDict[districtStats.districtId] = districtStats

                this.setState({
                  districtStats: districtStats,
                  allStatOptionsDict: allStatOptionsDict,
                })
              }
            })

          db.collection(ColType.schoolStats)
            .where("districtId", "==", admin.districtId)
            .get()
            .then((querySnapshot) => {
              var allStatOptionsDict = this.state.allStatOptionsDict

              querySnapshot.forEach((doc) => {
                var schoolStatsDoc = flattenDoc(doc)
                allStatOptionsDict[schoolStatsDoc.id] = schoolStatsDoc
              })

              this.setState({
                allStatOptionsDict: allStatOptionsDict,
              })
            })

          let districtListener = db.collection(ColType.district)
            .doc(admin.districtId)
            .onSnapshot((doc) => {
              var district = flattenDoc(doc)

              this.setState({
                district: district,
              })
            })
          if (this.state.districtListener) {
            this.state.districtListener()
          }
          this.setState({ districtListener: districtListener })


          if (this.state.districtSettingsListener) {
            this.state.districtSettingsListener()
          }
          let districtSettingsListener = db.collection(ColType.districtSettings)
            .doc(admin.districtId)
            .onSnapshot((doc) => {
              if (doc.exists) {
                var districtSettings = flattenDoc(doc)
                this.setState({
                  districtSettings: districtSettings,
                })
              } else {
                this.setState({
                  districtSettings: {
                    canReview: {
                      3: true,
                    },
                  },
                })
              }
            })
          this.setState({ districtSettingsListener: districtSettingsListener })

          // create a listener for schools
          var schoolListener = db.collection(ColType.school)
            .where('districtId', '==', admin.districtId)
            .onSnapshot((querySnapshot) => {
              var schools = []
              var schoolsDict = {}
              var schoolTypes = {
                elementarySchool: [],
                k8School: [],
                middleSchool: [],
                highSchool: [],
              }

              querySnapshot.forEach((doc) => {
                var school = flattenDoc(doc)
                schools.push(school)
                schoolsDict[school.id] = school
                if (!schoolTypes.hasOwnProperty(school.schoolType)) {
                  schoolTypes[school.schoolType] = []
                }
                schoolTypes[school.schoolType].push(school)
              })

              schools.sort((a, b) => {
                if (a.hasOwnProperty('schoolType') &&
                  b.hasOwnProperty('schoolType')) {
                  return this.getSchoolTypeSortWeight(a.schoolType) - this.getSchoolTypeSortWeight(b.schoolType)
                }

                return 0
              })


              this.setState({
                schools: schools,
                schoolsDict: schoolsDict,
                schoolTypes: schoolTypes,
              })
            })

          if (this.state.schoolListener) {
            this.state.schoolListener()
          }
          this.setState({ schoolListener: schoolListener })
        })
      })
  }

  componentWillUnmount() {
    if (this.state.authListener) {
      this.state.authListener()
    }
    if (this.state.districtListener) {
      this.state.districtListener()
    }
    if (this.state.schoolListener) {
      this.state.schoolListener()
    }
    if (this.state.districtSettingsListener) {
      this.state.districtSettingsListener()
    }
  }

  componentWillReceiveProps(props, newProps) {
    var split = getURLSplitArray(window.location)
    var pathId = split[split.length - 2]

    this.setState({
      pathId: pathId,
    })
  }

  render() {
    const { match: { url } } = this.props

    return (
      <Layout>
        <AdminHeader
          person={this.state.admin}
          schools={this.state.schools}
          adminBaseProps={this.state}
          selectedKey={this.state.pathId}
          history={this.props.history}
        >
        </AdminHeader>

        {/*<VideoContainer />*/}

        <Route path={`${url}/pacing-guide/`} render={props => PacingGuideComponent(props, this.state)} />

        <Route
          path={`${url}/admin-home/*`}
          render={props => AdminHomeComponent(props, this.state)}
        />
        <Route
          path={`${url}/admin-schools/*`}
          render={props => AdminSchoolsSelectComponent(props, this.state)}
        />
        <Route
          path={`${url}/school/*`}
          render={props => AdminSchoolComponent(props, this.state)}
        />
        <Route
          path={`${url}/teacher/*`}
          render={props => AdminTeacherViewComponent(props, this.state)}
        />
        <Route
          path={`${url}/student/*`}
          render={props => AdminStudentViewComponent(props, this.state)}
        />
        <Route
          path={`${url}/admin-teacher-practice/*`}
          render={props => AdminTeacherPracticeComponent(props, this.state)}
        />
        <Route
          path={`${url}/admin-instructional-facilitator-change-schools/*`}
          render={props => AdminInstructionalFacilitatorsSchoolsChangeComponent(props, this.state)}
        />
        <Route
          path={`${url}/change-user-school/*`}
          render={props => AdminChangeUserSchoolComponent(props, this.state)}
        />
        <Route
          path={`${url}/assessments/*`}
          render={props => AdminAssessmentAnalyticsComponent(props, this.state)}
        />
        <Route
          path={`${url}/admin-dashboard/*`}
          render={props => AdminDistrictStatsComponent(props, this.state)}
        />


      </Layout>
    )
  }
}

export default AdminBase