import React, { Component } from 'react';
import '../styles/GlobalStyle.css';
import { Select, Icon } from 'antd';
const { Option } = Select;

const schools = [
    "Pineapple Elementary School",
    "Rockaway Beach Middle School",
    "Rockaway Beach Middle School Campus 2",
    "Rockaway Beach Middle School Campus 3",
    "City High School"
];

const contentAreas = ["Math", "ELA", "Social Studies", "Science"];
const months = ["Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar", "Apr", "May"];
const gradeLevels = ["K", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

class DistrictStatsV3 extends Component {
    constructor(props) {
        super(props);
        this.seed = 42; // Changed seed value
        this.state = {
            selectedSchool: schools[0],
            selectedGrade: "K",
            performanceData: this.generatePerformanceData()
        };
    }

    handleSchoolChange = (value) => {
        this.setState({ selectedSchool: value, performanceData: this.generatePerformanceData() });
    };

    handleGradeChange = (value) => {
        this.setState({ selectedGrade: value, performanceData: this.generatePerformanceData() });
    };

    random() {
        // Simple linear congruential generator (LCG)
        const a = 1664525;
        const c = 1013904223;
        const m = Math.pow(2, 32);
        this.seed = (a * this.seed + c) % m;
        return this.seed / m;
    }

    generatePerformanceData() {
        return contentAreas.map(area => {
            const defaultProbability = 0.2 + this.random() * 0.3; // Default probability for each school between 75% and 90%
            let performance = defaultProbability;

            return {
                contentArea: area,
                values: months.map(month => {
                    const changeDirection = this.random();
                    if (changeDirection < 0.33) {
                        // Performance stays consistent
                        performance = performance;
                    } else if (changeDirection < 0.66) {
                        // Performance improves
                        performance = Math.min(1, performance + (this.random() * 0.2));
                    } else {
                        // Performance gets worse
                        performance = Math.max(0, performance - (this.random() * 0.2));
                    }
                    performance = Math.min(1, performance + 0.1); // Add +0.1 to make non-reporting less likely
                    const insufficientData = this.random() > performance; // Determine if non-reporting
                    const coachingNotes = Math.floor(this.random() * 3) + 1; // Random coaching notes quality 1 to 3
                    return { month, performance: insufficientData ? null : performance, coachingNotes };
                })
            };
        });
    }

    getColor(value) {
        if (value === null) return '#d3d3d3'; // Grey for no PLCs
        if (value < 0.5) return '#ff9999'; // Red for not on pace
        if (value < 0.75) return '#ffcc66'; // Yellow for approaching pace
        return '#99cc99'; // Green for on pace
    }

    getDarkerColor(value) {
        if (value === null) return '#a9a9a9'; // Darker grey
        if (value < 0.5) return '#cc6666'; // Darker red
        if (value < 0.75) return '#cc9933'; // Darker yellow
        return '#669966'; // Darker green
    }

    getIcon(value) {
        if (value === null) return <Icon type="stop" style={{ fontSize: '40px' }} />; // O with a line through it for no PLCs
        if (value < 0.5) return <Icon type="close" style={{ fontSize: '40px' }} />; // X for not on pace
        if (value < 0.75) return <Icon type="warning" style={{ fontSize: '40px' }} />; // Warning for approaching pace
        if (value >= 0.75) return <Icon type="check" style={{ fontSize: '40px' }} />; // Checkmark for on pace
        return ''; // No icon for yellow
    }

    getCoachingNotesColor(value) {
        if (value === 1) return '#ff9999'; // Red for poor notes
        if (value === 2) return '#ffcc66'; // Yellow for average notes
        if (value === 3) return '#99cc99'; // Green for good notes
        return '#d3d3d3'; // Grey for no data
    }

    render() {
        const { performanceData, selectedSchool, selectedGrade } = this.state;
        const cellSize = 100;
        const cellSpacing = 20;
        const areaSpacing = 40;

        return (
            <div style={{ padding: '20px' }}>
                <h1 className='font-50' style={{ color: '#ff6600' }}>PLC On Pace Monthly Report</h1>
                <h2 className='font-40' style={{ color: '#0066ff' }}>{selectedSchool}</h2>
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                    <Select
                        style={{ width: 300, marginRight: 40 }}
                        placeholder="Select a school"
                        value={this.state.selectedSchool}
                        onChange={this.handleSchoolChange}
                    >
                        {schools.map((school) => (
                            <Option key={school} value={school}>
                                {school}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        style={{ width: 150 }}
                        placeholder="Select a grade"
                        value={this.state.selectedGrade}
                        onChange={this.handleGradeChange}
                    >
                        {gradeLevels.map((grade) => (
                            <Option key={grade} value={grade}>
                                {grade}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div style={{ marginBottom: '40px', display: 'flex' }}>
                    <div style={{ display: 'inline-block', marginRight: '30px', padding: '15px', borderRadius: '5px', display: 'flex', alignItems: 'center', fontSize: '30px' }}>
                        <div style={{ backgroundColor: '#99cc99', width: '45px', height: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px solid #000' }}>
                            <Icon type="check" style={{ color: '#669966', fontSize: '30px' }} />
                        </div>
                        <span style={{ marginLeft: '15px' }}>On Pace (75% or more on-pace)</span>
                    </div>
                    <div style={{ display: 'inline-block', marginRight: '30px', padding: '15px', borderRadius: '5px', display: 'flex', alignItems: 'center', fontSize: '30px' }}>
                        <div style={{ backgroundColor: '#ffcc66', width: '45px', height: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px solid #000' }}>
                            <Icon type="warning" style={{ color: '#cc9933', fontSize: '30px' }} />
                        </div>
                        <span style={{ marginLeft: '15px' }}>Approaching Pace (50% or more on-pace)</span>
                    </div>
                    <div style={{ display: 'inline-block', marginRight: '30px', padding: '15px', borderRadius: '5px', display: 'flex', alignItems: 'center', fontSize: '30px' }}>
                        <div style={{ backgroundColor: '#ff9999', width: '45px', height: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px solid #000' }}>
                            <Icon type="close" style={{ color: '#cc6666', fontSize: '30px' }} />
                        </div>
                        <span style={{ marginLeft: '15px' }}>Not on Pace (49% or less on-pace)</span>
                    </div>
                    <div style={{ display: 'inline-block', padding: '15px', borderRadius: '5px', display: 'flex', alignItems: 'center', fontSize: '30px' }}>
                        <div style={{ backgroundColor: '#d3d3d3', width: '45px', height: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px solid #000' }}>
                            <Icon type="stop" style={{ color: '#a9a9a9', fontSize: '30px' }} />
                        </div>
                        <span style={{ marginLeft: '15px' }}>No PLCs (No PLCs reported)</span>
                    </div>
                </div>


                {performanceData.map((areaData, areaIndex) => (
                    <div key={areaIndex} style={{ marginBottom: areaSpacing }}>
                        <h2 className='font-30' style={{ color: '#0066ff' }}>{areaData.contentArea} - Grade: {selectedGrade}</h2>
                        <svg className="performance-chart" width="100%" height={cellSize + 140}>
                            {areaData.values.map((value, colIndex) => (
                                <g key={colIndex} className="performance-cell">
                                    <rect
                                        x={colIndex * (cellSize + cellSpacing)}
                                        y={50}
                                        width={cellSize}
                                        height={cellSize}
                                        fill={this.getColor(value.performance)}
                                        stroke="#000000"
                                        strokeWidth="2"
                                    />
                                    <foreignObject
                                        x={colIndex * (cellSize + cellSpacing)}
                                        y={50}
                                        width={cellSize}
                                        height={cellSize}
                                    >
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100%',
                                            color: this.getDarkerColor(value.performance),
                                            fontSize: '40px'
                                        }}>
                                            {this.getIcon(value.performance)}
                                        </div>
                                    </foreignObject>
                                    <text
                                        x={colIndex * (cellSize + cellSpacing) + cellSize / 2}
                                        y={cellSize + 100}
                                        textAnchor="middle"
                                        fontSize="24"
                                        fontWeight="bold"
                                    >
                                        {value.month}
                                    </text>
                                </g>
                            ))}
                        </svg>
                    </div>
                ))}
                <h1 className='font-50' style={{ marginTop: '80px', color: '#ff6600' }}>Coaching Notes Monthly Report</h1>
                <div className="coaching-notes-container" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                    {performanceData.map((areaData, areaIndex) => (
                        <div key={areaIndex} style={{
                            border: '2px solid #ff6600',
                            borderRadius: '10px',
                            padding: '20px',
                            margin: '10px',
                            width: '250px',
                            backgroundColor: '#fff5e6',
                            boxShadow: '0px 0px 15px rgba(255, 102, 0, 0.7)'
                        }}>
                            <h2 className='font-24' style={{ color: '#ff6600' }}>{areaData.contentArea} - Grade: {selectedGrade}</h2>
                            <div>
                                {areaData.values.map((value, colIndex) => (
                                    <div key={colIndex} style={{ marginBottom: '10px' }}>
                                        <span style={{ fontWeight: 'bold', marginRight: '10px', fontSize: '18px' }}>{value.month}:</span>
                                        <div style={{
                                            height: '20px',
                                            width: '100%',
                                            backgroundColor: '#d3d3d3',
                                            borderRadius: '5px',
                                            overflow: 'hidden',
                                            position: 'relative',
                                            display: 'inline-block',
                                            verticalAlign: 'middle'
                                        }}>
                                            <div style={{
                                                width: `${value.coachingNotes * 33.33}%`,
                                                backgroundColor: this.getCoachingNotesColor(value.coachingNotes),
                                                height: '100%',
                                                position: 'relative'
                                            }}>
                                                <span style={{
                                                    position: 'absolute',
                                                    width: '100%',
                                                    textAlign: 'center',
                                                    color: '#000',
                                                    fontWeight: 'bold'
                                                }}>{value.coachingNotes}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default DistrictStatsV3;
