import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import TeacherHome from './TeacherHome'
import ContactUs from './student/ContactUs'
import NeedHelp from './student/NeedHelp'
import TeacherChatHome from './chat/TeacherChatHome'
import TierOneHome from './student/TierOneHome'
import TierOnePlan from './student/TierOnePlan'
import TeacherAddStudentHome from './student/TeacherAddStudentHome'
import TeacherAddStudentsCleverHome from './student/TeacherAddStudentsCleverHome'
import AddStudentCSV from './student/AddStudentCSV'
import EdvizComponentTest from './edvizv2/EdvizComponentTest'
import PacingGuide from './pacingguide/PacingGuide'
import PacingGuideCleverTest from './pacingguide/PacingGuideTeacherAsessmentSC'
import PacingGuideLessonPlan from './pacingguide/PacingGuideLessonPlanV2'
import PacingGuideTeacherAssessmentsBase from './pacingguide/PacingGuideTeacherAssessmentsBase'
import TeacherHelpDesk from './helpdesk/TeacherHelpDesk'
import TeacherHelpDeskGettingStarted from './helpdesk/TeacherHelpDeskGettingStarted'
import TeacherHelpDeskAddingAStudent from './helpdesk/TeacherHelpDeskAddingAStudent'
import TeacherHelpDeskAddIEPStudent from './helpdesk/TeacherHelpDeskAddIEPStudent'
import TeacherHelpDeskAddIEPInformation from './helpdesk/TeacherHelpDeskAddIEPInformation'
import TeacherHelpDeskAddIEPSelect from './helpdesk/TeacherHelpDeskAddIEPSelect'
import TeacherHelpDeskAddIEPModify from './helpdesk/TeacherHelpDeskAddIEPModify'
import TeacherHelpDeskAddIEPProgressMonitoring from './helpdesk/TeacherHelpDeskAddIEPProgressMonitoring'
import TeacherHelpDeskAddIEPService from './helpdesk/TeacherHelpDeskAddIEPService'
import TeacherHelpDeskAddIEPAccommodations from './helpdesk/TeacherHelpDeskAddIEPAccommodations'
import TeacherHelpDeskAddIEPConfirm from './helpdesk/TeacherHelpDeskAddIEPConfirm'
import TeacherTemporaryAddStudent from './student/TeacherTemporaryAddStudent'
import TeacherPLC from './reports/TeacherPLC'
import TeacherMeetings from './student/TeacherMeetings'
import FormHome from './california-iep/FormHome'
import FormStudent from './california-iep/FormStudent'
import FormPartOne from './california-iep/FormPartOne'
import FormPartTwo from './california-iep/FormPartTwo'
import TeacherClassStatus from './TeacherClassStatus'
import TeacherReportsBase from './reports/TeacherReportsBase'
//import TeacherReports from './reports/TeacherReports'
//import ArchivedBase from './student/archived/ArchivedBase'
import DirectoryBase from './student/base_directory/DirectoryBase'
import TeacherRecords from './reports/TeacherRecords'
import TeacherReportSelect from './reports/TeacherReportSelect'
import TeacherStudentUploadFileView from './reports/TeacherStudentUploadFileView'
import TeacherStudentUploadView from './reports/TeacherStudentUploadView'
import TeacherStudentUploadSelect from './reports/TeacherStudentUploadSelect'
import TeacherStudentReport from './reports/TeacherStudentReport'
import TeacherStudentRecords from './reports/TeacherStudentRecords'
import TeacherGroupReports from './reports/TeacherGroupReports'
import TeacherGroupLessonPlan from './reports/TeacherGroupLessonPlan'
//import TeacherStudent from './student/TeacherStudent'
import TeacherStudentView from './student/TeacherStudentView'
import TeacherStudentPlan from './student/TeacherStudentPlan'
import TeacherUpdateStudent from './student/TeacherUpdateStudent'
import TeacherAddIEPStart from './addiep/TeacherAddIEPGoalStart'
import TeacherAddIEPStudent from './addiep/TeacherAddIEPGoalStudent'
import TeacherAddIEPInformation from './addiep/TeacherAddIEPGoalInformation'
import TeacherAddIEPSelect from './addiep/TeacherAddIEPGoalSelect'
import TeacherAddIEPModify from './addiep/TeacherAddIEPGoalModify'
import TeacherAddIEPProgressMonitoring from './addiep/TeacherAddIEPGoalProgressMonitoring'
import TeacherAddIEPService from './addiep/TeacherAddIEPGoalService'
import TeacherAddIEPAccommodations from './addiep/TeacherAddIEPGoalAccommodations'
import TeacherAddIEPConfirm from './addiep/TeacherAddIEPGoalConfirm'
import PEPTeacherAddIEPStudent from './addpep/PEPTeacherAddIEPGoalStudent'
import PEPTeacherAddIEPInformation from './addpep/PEPTeacherAddIEPGoalInformation'
import PEPTeacherAddIEPSelect from './addpep/PEPTeacherAddIEPGoalSelect'
import PEPTeacherAddIEPModify from './addpep/PEPTeacherAddIEPGoalModify'
import PEPTeacherAddIEPProgressMonitoring from './addpep/PEPTeacherAddIEPGoalProgressMonitoring'
import PEPTeacherAddIEPService from './addpep/PEPTeacherAddIEPGoalService'
import PEPTeacherAddIEPAccommodations from './addpep/PEPTeacherAddIEPGoalAccommodations'
import PEPTeacherAddIEPConfirm from './addpep/PEPTeacherAddIEPGoalConfirm'
import Scheduler from './scheduler/Scheduler'
import ProgressMonitoring from './progressmonitoring/ProgressMonitoring'
//import StudentReport from './reports/StudentReport'
import '../styles/GlobalStyle.css'
import { firebase, db } from '../firebase/Firebase'
import TeacherHeader from '../login/TeacherHeader'
import TeacherHeaderInitial from '../login/TeacherHeaderInitial'
import AdminHeader from '../login/AdminHeader'
import SchoolAdminHeader from '../login/SchoolAdminHeader'
import InstructionalFacilitatorHeader from '../login/InstructionalFacilitatorHeader'
//import TeacherSupportDesk from '../customcomponents/TeacherSupportDesk'
import CreatePaidAccount from '../login/CreatePaidAccount'
//import VideoContainer from '../video/VideoContainer'
import {
  flattenDoc, getURLSplitArray, linkAfterLogin,
  getLevelObj, isSandboxNoSub, isSandboxNoGoalsLeft, isReadOnlyTeacherId, getUploadedFileId,
  doesChatNotificationDictHaveNotifications,
  FOLDER_CURRENT,
  FOLDER_ARCHIVED,
  FOLDER_TRASHBIN,
  FOLDER_TRANSFER,
  getDistrictLogo,
  getQueryStringParam
} from '../Util'
import ColType from '../Types'
//import { getReadOnlyTeacherData3 } from '../readonlydata3'
import { Layout } from 'antd'
import TeacherHeaderForms from '../login/TeacherHeaderForms'
const moment = require('moment')
const Promise = require("bluebird")

const TeacherEdvizComponentTestComponent = (props, state) => {
  return <EdvizComponentTest teacherBaseProps={state} {...props} />
}

const TeacherAddStudentsCleverHomeComponent = (props, state) => {
  return <TeacherAddStudentsCleverHome teacherBaseProps={state} {...props} />
}

const TeacherPLCComponent = (props, state) => {
  return <TeacherPLC teacherBaseProps={state} {...props} />
}

const TeacherMeetingsComponent = (props, state) => {
  return <TeacherMeetings teacherBaseProps={state} {...props} />
}

const PacingGuideComponent = (props, state) => {
  return <PacingGuide teacherBaseProps={state} {...props} />
}

const PacingGuideLessonPlanComponent = (props, state) => {
  return <PacingGuideLessonPlan teacherBaseProps={state} {...props} />
}

const PacingGuideCleverTestComponent = (props, state) => {
  return <PacingGuideCleverTest teacherBaseProps={state} {...props} />
}

const PacingGuideTeacherAssessmentsBaseComponent = (props, state) => {
  return <PacingGuideTeacherAssessmentsBase teacherBaseProps={state} {...props} />
}

const TeacherAddStudentHomeComponent = (props, state) => {
  return <TeacherAddStudentHome teacherBaseProps={state} {...props} />
}

const TeacherTemporaryAddStudentComponent = (props, state) => {
  return <TeacherTemporaryAddStudent teacherBaseProps={state} {...props} />
}

const AddStudentCSVComponent = (props, state) => {
  return <AddStudentCSV teacherBaseProps={state} {...props} />
}

const TierOneHomeComponent = (props, state) => {
  return <TierOneHome teacherBaseProps={state} {...props} />
}

const TierOnePlanComponent = (props, state) => {
  return <TierOnePlan teacherBaseProps={state} {...props} />
}

const TeacherChatHomeComponent = (props, state) => {
  return <TeacherChatHome teacherBaseProps={state} {...props} />
}

const TeacherHelpDeskComponent = (props, state) => {
  return <TeacherHelpDesk teacherBaseProps={state} {...props} />
}

const TeacherHelpDeskGettingStartedComponent = (props, state) => {
  return <TeacherHelpDeskGettingStarted teacherBaseProps={state} {...props} />
}

const TeacherArchivedBaseComponent = (props, state) => {
  return <DirectoryBase teacherBaseProps={state} baseFolder={FOLDER_ARCHIVED} {...props} />
}

const TeacherTransferBaseComponent = (props, state) => {
  return <DirectoryBase teacherBaseProps={state} baseFolder={FOLDER_TRANSFER} {...props} />
}

const TeacherDeletedBaseComponent = (props, state) => {
  return <DirectoryBase teacherBaseProps={state} baseFolder={FOLDER_TRASHBIN} {...props} />
}

const TeacherHelpDeskAddingAStudentComponent = (props, state) => {
  return <TeacherHelpDeskAddingAStudent teacherBaseProps={state} {...props} />
}

const TeacherHelpDeskAddIEPStudentComponent = (props, state) => {
  return <TeacherHelpDeskAddIEPStudent teacherBaseProps={state} {...props} />
}

const TeacherHelpDeskAddIEPInformationComponent = (props, state) => {
  return <TeacherHelpDeskAddIEPInformation teacherBaseProps={state} {...props} />
}

const TeacherHelpDeskAddIEPSelectComponent = (props, state) => {
  return <TeacherHelpDeskAddIEPSelect teacherBaseProps={state} {...props} />
}

const TeacherHelpDeskAddIEPModifyComponent = (props, state) => {
  return <TeacherHelpDeskAddIEPModify teacherBaseProps={state} {...props} />
}

const TeacherHelpDeskAddIEPProgressMonitoringComponent = (props, state) => {
  return <TeacherHelpDeskAddIEPProgressMonitoring teacherBaseProps={state} {...props} />
}

const TeacherHelpDeskAddIEPServiceComponent = (props, state) => {
  return <TeacherHelpDeskAddIEPService teacherBaseProps={state} {...props} />
}

const TeacherHelpDeskAddIEPAccommodationsComponent = (props, state) => {
  return <TeacherHelpDeskAddIEPAccommodations teacherBaseProps={state} {...props} />
}

const TeacherHelpDeskAddIEPConfirmComponent = (props, state) => {
  return <TeacherHelpDeskAddIEPConfirm teacherBaseProps={state} {...props} />
}

const TeacherHomeComponent = (props, state) => {
  return <TeacherHome teacherBaseProps={state} {...props} />
}

const CreatePaidAccountComponent = (props, state) => {
  return <CreatePaidAccount teacherBaseProps={state} {...props} />
}

const TeacherClassComponent = (props, state) => {
  return <TeacherClassStatus teacherBaseProps={state} {...props} />
}

const TeacherContactUsComponent = (props, state) => {
  return <ContactUs teacherBaseProps={state} {...props} />
}

const TeacherNeedHelpComponent = (props, state) => {
  return <NeedHelp teacherBaseProps={state} {...props} />
}

const FormHomeComponent = (props, state) => {
  return <FormHome teacherBaseProps={state} {...props} />
}

const FormStudentComponent = (props, state) => {
  return <FormStudent teacherBaseProps={state} {...props} />
}

const FormPartOneComponent = (props, state) => {
  return <FormPartOne teacherBaseProps={state} {...props} />
}

const FormPartTwoComponent = (props, state) => {
  return <FormPartTwo teacherBaseProps={state} {...props} />
}

const TeacherReportsBaseComponent = (props, state) => {
  if (isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <TeacherReportsBase teacherBaseProps={state} {...props} />
}

const TeacherReportsComponent = (props, state) => {
  if (isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <TeacherReportSelect teacherBaseProps={state} {...props} />
}

const TeacherRecordsComponent = (props, state) => {
  if (isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <TeacherRecords teacherBaseProps={state} {...props} />
}

const TeacherStudentUploadSelectComponent = (props, state) => {
  if (isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <TeacherStudentUploadSelect teacherBaseProps={state} {...props} />
}

const TeacherStudentUploadViewComponent = (props, state) => {
  if (isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <TeacherStudentUploadView teacherBaseProps={state} {...props} />
}

const TeacherStudentUploadFileViewComponent = (props, state) => {
  if (isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <TeacherStudentUploadFileView teacherBaseProps={state} {...props} />
}


const TeacherStudentRecordsComponent = (props, state) => {
  if (isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <TeacherStudentRecords teacherBaseProps={state} {...props} />
}

const TeacherStudentReportComponent = (props, state) => {
  return <TeacherStudentReport teacherBaseProps={state} {...props} />
}

const TeacherUpdateStudentComponent = (props, state) => {
  return <TeacherUpdateStudent teacherBaseProps={state} {...props} />
}

/*
const TeacherStudentComponent = (props, state) => {
  return <TeacherStudent teacherBaseProps={state} {...props} />
}
*/

const TeacherStudentViewComponent = (props, state) => {
  return <TeacherStudentView teacherBaseProps={state} {...props} />
}

const TeacherStudentPlanComponent = (props, state) => {
  return <TeacherStudentPlan teacherBaseProps={state} {...props} />
}


const TeacherGroupReportsComponent = (props, state) => {
  if (isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <TeacherGroupReports teacherBaseProps={state} {...props} />
}

const TeacherGroupLessonPlanComponent = (props, state) => {
  if (isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <TeacherGroupLessonPlan teacherBaseProps={state} {...props} />
}

const TeacherSchedulerComponent = (props, state) => {
  if (isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <Scheduler teacherBaseProps={state} {...props} />
}

const TeacherProgressMonitoringComponent = (props, state) => {
  if (isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <ProgressMonitoring teacherBaseProps={state} {...props} />
}


const TeacherAddIEPStartComponent = (props, state) => {
  if (isSandboxNoGoalsLeft(state) && isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <TeacherAddIEPStart teacherBaseProps={state} {...props} />
}

const TeacherAddIEPStudentComponent = (props, state) => {
  if (isSandboxNoGoalsLeft(state) && isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <TeacherAddIEPStudent teacherBaseProps={state} {...props} />
}

const TeacherAddIEPInformationComponent = (props, state) => {
  if (isSandboxNoGoalsLeft(state) && isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <TeacherAddIEPInformation teacherBaseProps={state} {...props} />
}

const TeacherAddIEPSelectComponent = (props, state) => {
  if (isSandboxNoGoalsLeft(state) && isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <TeacherAddIEPSelect teacherBaseProps={state} {...props} />
}

const TeacherAddIEPModifyComponent = (props, state) => {
  if (isSandboxNoGoalsLeft(state) && isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <TeacherAddIEPModify teacherBaseProps={state} {...props} />
}

const TeacherAddIEPProgressMonitoringComponent = (props, state) => {
  if (isSandboxNoGoalsLeft(state) && isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <TeacherAddIEPProgressMonitoring teacherBaseProps={state} {...props} />
}

const TeacherAddIEPServiceComponent = (props, state) => {
  if (isSandboxNoGoalsLeft(state) && isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <TeacherAddIEPService teacherBaseProps={state} {...props} />
}

const TeacherAddIEPAccommodationsComponent = (props, state) => {
  if (isSandboxNoGoalsLeft(state) && isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <TeacherAddIEPAccommodations teacherBaseProps={state} {...props} />
}

const TeacherAddIEPConfirmComponent = (props, state) => {
  if (isSandboxNoGoalsLeft(state) && isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <TeacherAddIEPConfirm teacherBaseProps={state} {...props} />
}

// pep
const PEPTeacherAddIEPStudentComponent = (props, state) => {
  if (isSandboxNoGoalsLeft(state) && isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <PEPTeacherAddIEPStudent teacherBaseProps={state} {...props} />
}

const PEPTeacherAddIEPInformationComponent = (props, state) => {
  if (isSandboxNoGoalsLeft(state) && isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <PEPTeacherAddIEPInformation teacherBaseProps={state} {...props} />
}

const PEPTeacherAddIEPSelectComponent = (props, state) => {
  if (isSandboxNoGoalsLeft(state) && isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <PEPTeacherAddIEPSelect teacherBaseProps={state} {...props} />
}

const PEPTeacherAddIEPModifyComponent = (props, state) => {
  if (isSandboxNoGoalsLeft(state) && isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <PEPTeacherAddIEPModify teacherBaseProps={state} {...props} />
}

const PEPTeacherAddIEPProgressMonitoringComponent = (props, state) => {
  if (isSandboxNoGoalsLeft(state) && isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <PEPTeacherAddIEPProgressMonitoring teacherBaseProps={state} {...props} />
}

const PEPTeacherAddIEPServiceComponent = (props, state) => {
  if (isSandboxNoGoalsLeft(state) && isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <PEPTeacherAddIEPService teacherBaseProps={state} {...props} />
}

const PEPTeacherAddIEPAccommodationsComponent = (props, state) => {
  if (isSandboxNoGoalsLeft(state) && isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <PEPTeacherAddIEPAccommodations teacherBaseProps={state} {...props} />
}

const PEPTeacherAddIEPConfirmComponent = (props, state) => {
  if (isSandboxNoGoalsLeft(state) && isSandboxNoSub(state)) {
    return CreatePaidAccountComponent(props, state)
  }
  return <PEPTeacherAddIEPConfirm teacherBaseProps={state} {...props} />
}

class TeacherBase extends Component {
  state = {
    user: null,
    teacherId: '',
    teacher: null,
    students: null,
    studentDict: null,
    school: null,
    district: null,
    grades: null,
    IEPGoals: null,
    IEPDict: null,
    IEPNumGoalDict: null,
    archivedGoals: null,
    nonArchivedGoals: null,
    studentRequiresAction: null,
    studentsHighestTier: null,
    studentsLevel: null,
    studentOnTrack: null,
    studentNumGoals: null,
    studentIEPDict: null,
    schools: null,
    schoolsDict: null,
    totalOnTrack: null,
    calendarEvents: null,
    calendarDict: null,
    uploadedFiles: null,
    uploadedFilesDict: null,
    studentUploadedFilesDict: null,
    uploadedFilesFileNameDict: null,
    events: null,
    eventDict: null,
    IEPScheduled: null,
    teacherStats: null,
    chatMessages: null,
    currentTimeStamp: null,
    adminBaseProps: null,
    schoolAdminBaseProps: null,
    instructionalFacilitatorBaseProps: null,
    chatNotificationDict: {},
    iepDrafts: null,
    iepDraftsDict: null,
    pepDrafts: null,
    pepDraftsDict: null,
    generalNotifications: null,
    generalNotificationsNumUnvisited: 0,
    readOnlyIEPMeasurementDict: {},
    readOnlyIEPNoteDict: {},
    addToReadOnlyIEPMeasurementDictFunc: null,
    addToReadOnlyIEPNoteDictFunc: null,
    readOnly: false,
    pathId: '',
    headerKey: '',
    onboardAlreadyShown: false,
    setOnboardAlreadyShown: null,
    hasChatNotifications: false,
    currentlyViewingChatId: null,
    setCurrentlyViewingChatIdFunc: null,
    showEvent: null,
    setShowEventFunc: null,
    districtLogo: null,
    sections: null,
    sectionsDict: null,
    districtSettings: null,
    snapshotListeners: {},
    demoToken: null,
  }

  setShowEvent = (showEventId) => {
    // send a props update of showEvent, then
    // set it to null so that componentwillreceiveprops
    // only fires once in the target component
    this.setState({
      showEvent: showEventId,
    }, () => {
      this.setState({
        showEvent: null,
      })
    })
  }

  setCurrentlyViewingChatId = (chatId) => {
    this.setState({
      currentlyViewingChatId: chatId
    })
  }

  setOnboardAlreadyShown = (shown) => {
    this.setState({
      onboardAlreadyShown: shown,
    })
  }

  addToReadOnlyIEPMeasurementDict = (IEPId, measurements) => {
    var readOnlyIEPMeasurementDict = this.state.readOnlyIEPMeasurementDict
    readOnlyIEPMeasurementDict[IEPId] = measurements
    this.setState({
      readOnlyIEPMeasurementDict: readOnlyIEPMeasurementDict
    })
  }

  addToReadOnlyIEPNoteDict = (IEPId, notes) => {
    var readOnlyIEPNoteDict = this.state.readOnlyIEPNoteDict
    readOnlyIEPNoteDict[IEPId] = notes
    this.setState({
      readOnlyIEPNoteDict: readOnlyIEPNoteDict
    })
  }

  // Do fetch here
  componentDidMount() {
    var split = getURLSplitArray(window.location)
    var pathId = split[split.length - 2]
    var teacherId = split[split.length - 1]
    let demoToken = getQueryStringParam('dt')

    this.setState({
      pathId: pathId,
      teacherId: teacherId,
      demoToken: demoToken,
      addToReadOnlyIEPMeasurementDictFunc: this.addToReadOnlyIEPMeasurementDict,
      addToReadOnlyIEPNoteDictFunc: this.addToReadOnlyIEPNoteDict,
      setCurrentlyViewingChatIdFunc: this.setCurrentlyViewingChatId,
      setShowEventFunc: this.setShowEvent,
      onboardAlreadyShown: false,
      setOnboardAlreadyShown: this.setOnboardAlreadyShown,
    })

    // set state to the static read only teacher data and return
    if (isReadOnlyTeacherId(teacherId)) {
      /*
      this.setState(getReadOnlyTeacherData3())
      // add these after setting state as they are not in the object in the readonly data file
      this.setState({
        onboardAlreadyShown: false,
        setOnboardAlreadyShown: this.setOnboardAlreadyShown,
      })
      */
      return
    }

    new Promise((resolve, reject) => {
      var authListener = firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // User is signed in.
          db.collection(ColType.users)
            .doc(user.uid)
            .get()
            .then((doc) => {

              var userObj = doc.data()
              userObj.id = doc.id
              userObj.uid = user.uid
              userObj.signedIn = true
              userObj.emailVerified = user.emailVerified
              //console.log("actual user", user)
              //console.log('user', userObj)
              //console.log(userObj.accessLevel)
              this.setState({ user: userObj }, () => {
                resolve()
              })

              // if the user is not a teacher, see if we have the adminBaseProps state
              // which is passed via Link when the admin clicks on this teacher.
              // If we don't have it (user refreshes the page for example so the state
              // from Link is gone, then redirect to admin/home). Not ideal, but probably fine.
              if (userObj.accessLevel !== 'teachers') {
                // if we have adminBaseProps via Link state, set it.
                if (this.props &&
                  this.props.hasOwnProperty('location') && this.props.location &&
                  this.props.location.hasOwnProperty('state') && this.props.location.state &&
                  this.props.location.state.hasOwnProperty('adminBaseProps')) {
                  this.setState({
                    adminBaseProps: this.props.location.state.adminBaseProps,
                    readOnly: true,
                  })
                }
                else if (this.props &&
                  this.props.hasOwnProperty('location') && this.props.location &&
                  this.props.location.hasOwnProperty('state') && this.props.location.state &&
                  this.props.location.state.hasOwnProperty('schoolAdminBaseProps')) {
                  this.setState({
                    schoolAdminBaseProps: this.props.location.state.schoolAdminBaseProps,
                    readOnly: true,
                  })
                }
                else if (this.props &&
                  this.props.hasOwnProperty('location') && this.props.location &&
                  this.props.location.hasOwnProperty('state') && this.props.location.state &&
                  this.props.location.state.hasOwnProperty('instructionalFacilitatorBaseProps')) {
                  this.setState({
                    instructionalFacilitatorBaseProps:
                      this.props.location.state.instructionalFacilitatorBaseProps,
                    readOnly: this.props.location.state
                      .instructionalFacilitatorBaseProps.instructionalFacilitator.id !== teacherId,
                  })
                }
                // else get the user's accessLevel (admin) information and link back to their home
                // page.
                else {
                  db.collection(userObj.accessLevel)
                    .doc(userObj.refId)
                    .onSnapshot((doc) => {
                      var userJobInfo = flattenDoc(doc)
                      //console.log(userObj, userJobInfo)
                      linkAfterLogin(userObj, userJobInfo)
                    })
                }
              }
              else {
                this.setState({
                  readOnly: false,
                })
              }
            })
        }
        else {
          reject()
          // No user is signed in.
          if (this.state.user &&
            this.state.user.hasOwnProperty('districtId') &&
            this.state.user.districtId === 'vYTvkDwoLx19NkA6dJO8') {
            this.props.history.push(
              {
                pathname: '/beta/sign-in/'
              }
            )
          } else {
            this.props.history.push(
              {
                pathname: '/sign-in/'
              }
            )
          }
        }
      })

      var snapshotListeners = this.state.snapshotListeners
      // if this ever happens, unsubscribe old listener before setting the new one
      if (snapshotListeners.hasOwnProperty('authListener')) {
        snapshotListeners['authListener']()
      }
      snapshotListeners['authListener'] = authListener
      this.setState({
        snapshotListeners: snapshotListeners
      })
    }).then(() => {
      return new Promise((resolve, reject) => {
        // get the current timestamp
        db.collection(ColType.time)
          .doc(teacherId)
          .set({ time: firebase.firestore.FieldValue.serverTimestamp() })
          .then(() => {
            db.collection(ColType.time)
              .doc(teacherId)
              .get()
              .then((doc) => {
                var currentTimeStamp = flattenDoc(doc)
                //console.log("current ts", currentTimeStamp)
                currentTimeStamp = moment.utc(currentTimeStamp.time.seconds * 1000)

                this.setState({
                  currentTimeStamp: currentTimeStamp,
                }, () => {
                  resolve()
                })
              })
          })
      })
    }).then(() => {
      return new Promise((resolve, reject) => {
        // user from auth is received at this point in put in state as user
        // this listener is for paid subscribers, so their last subscription
        // timestamp is updated from the server and they can add goals again
        // without having to refresh to get their updated user.
        var userListener = db.collection(ColType.users)
          .doc(this.state.user.uid)
          .onSnapshot((doc) => {
            var user = flattenDoc(doc)
            var mergedUser = Object.assign(this.state.user, user)
            //console.log("user update", mergedUser)
            this.setState({
              user: mergedUser,
            }, () => {
              //console.log("user before update timestamp")
              db.collection(ColType.time)
                .doc(teacherId)
                .set({ time: firebase.firestore.FieldValue.serverTimestamp() })
                .then(() => {
                  db.collection(ColType.time)
                    .doc(teacherId)
                    .get()
                    .then((doc) => {
                      var currentTimeStamp = flattenDoc(doc)
                      //console.log("user update timestamp", currentTimeStamp)
                      currentTimeStamp = moment.utc(currentTimeStamp.time.seconds * 1000)

                      if (this.state.user &&
                        this.state.user.hasOwnProperty('isLocked') &&
                        this.state.user.isLocked) {
                        this.props.history.push(
                          {
                            pathname: '/account-is-locked'
                          }
                        )
                      }

                      if (this.state.user &&
                        this.state.user.hasOwnProperty('timeStamp') &&
                        this.state.user.hasOwnProperty('isSandbox') &&
                        this.state.user.isSandbox &&
                        // If they have this key I set their sandbox status artifically,
                        // so don't make them verify their email. This means I created
                        // their account, they didnt create it, and accounts I create
                        // don't need to have their emails verified.
                        !this.state.user.hasOwnProperty("isSandboxSetByDistrict") &&
                        this.state.user.accessLevel === 'teachers') {
                        if (!this.state.user.emailVerified) {
                          // send them to sign in where they will be redirected
                          this.props.history.push(
                            {
                              pathname: '/email-not-yet-verified'
                            }
                          )
                        }
                        // the user bought a year subscription from their sandbox trial account
                        if (this.state.user.hasOwnProperty('lastSubscriptionTimeStamp') &&
                          this.state.user.lastSubscriptionTimeStamp) {
                          var subscriptionEndDate =
                            moment.utc(this.state
                              .user.lastSubscriptionTimeStamp.seconds * 1000).add(366, 'days')
                          var subscriptionDaysLeft = subscriptionEndDate.diff(currentTimeStamp, 'days')
                          var userObj = this.state.user
                          userObj.subscriptionEndDate = subscriptionEndDate
                          userObj.subscriptionDaysLeft = subscriptionDaysLeft
                          this.setState({
                            user: userObj,
                          })
                        }
                      }

                      this.setState({
                        currentTimeStamp: currentTimeStamp,
                      })
                    })
                })
            })
          }) // user listener end

        var snapshotListeners = this.state.snapshotListeners
        // if this ever happens, unsubscribe old listener before setting the new one
        if (snapshotListeners.hasOwnProperty('userListener')) {
          snapshotListeners['userListener']()
        }
        snapshotListeners['userListener'] = userListener
        this.setState({
          snapshotListeners: snapshotListeners
        }, () => {
          resolve()
        })
      })
    }).then(() => {
      return new Promise((resolve, reject) => {
        var districtId
        var schoolId
        // admin has no school
        if (this.state.adminBaseProps) {
          districtId = this.state.adminBaseProps.admin.districtId
        }
        else if (this.state.schoolAdminBaseProps) {
          districtId = this.state.schoolAdminBaseProps.schoolAdmin.districtId
          schoolId = this.state.schoolAdminBaseProps.schoolAdmin.schoolId
        }

        // school admin's query
        if (districtId && schoolId) {
          db.collection(ColType.teacher)
            .where(firebase.firestore.FieldPath.documentId(), '==', teacherId)
            .where('schoolId', '==', schoolId)
            .where('districtId', '==', districtId)
            .get()
            .then((querySnapshot) => {
              //console.log('query len', querySnapshot.empty)
              var doc = querySnapshot.docs[0]
              // console.log(doc, doc.id, doc.data())
              var teacher = flattenDoc(doc)
              //console.log('Admin teacher', teacher)
              this.setState({
                pathId: pathId,
                teacherId: teacherId,
                teacher: teacher,
              }, () => {
                //console.log("Reached teacher", this.state.teacher)
                resolve()
              })
            })
        }
        // admin's query
        else if (districtId) {
          db.collection(ColType.teacher)
            .where(firebase.firestore.FieldPath.documentId(), '==', teacherId)
            .where('districtId', '==', districtId)
            .get()
            .then((querySnapshot) => {
              //console.log('query len', querySnapshot.empty)
              var doc = querySnapshot.docs[0]
              //console.log(doc, doc.id, doc.data())
              var teacher = flattenDoc(doc)
              //console.log('Admin teacher', teacher)
              this.setState({
                pathId: pathId,
                teacherId: teacherId,
                teacher: teacher,
              }, () => {
                //console.log("Reached teacher", this.state.teacher)
                resolve()
              })
            })
        }
        // teacher's query
        else {
          db.collection(ColType.teacher)
            .doc(teacherId)
            .get()
            .then((doc) => {
              var teacher = flattenDoc(doc)
              this.setState({
                pathId: pathId,
                teacherId: teacherId,
                teacher: teacher,
              }, () => {
                resolve()
              })
            })
        }
      })
    }).then(() => {
      return new Promise((resolve, reject) => {
        db.collection(ColType.district)
          .doc(this.state.teacher.districtId)
          .get()
          .then((doc) => {
            var district = flattenDoc(doc)
            this.setState({
              district: district,
              districtLogo: getDistrictLogo(district.district, district.state)
            })
          })

        // get schools in the district
        db.collection(ColType.school)
          .where('districtId', '==', this.state.teacher.districtId)
          .get()
          .then((querySnapshot) => {
            var schools = []
            var schoolsDict = {}

            querySnapshot.forEach((doc) => {
              var school = flattenDoc(doc)
              schools.push(school)
              schoolsDict[school.id] = school
            })

            this.setState({
              schools: schools,
              schoolsDict: schoolsDict,
            })
          })

        let districtSettingsListener = db.collection(ColType.districtSettings)
          .doc(this.state.teacher.districtId)
          .onSnapshot({ includeMetadataChanges: true }, (doc) => {
            // if from cache return, only want server updates
            if (doc.metadata.fromCache) {
              return
            }
            if (doc.exists) {
              var districtSettings = flattenDoc(doc)
              this.setState({
                districtSettings: districtSettings,
              })
            }
          })

        var teacherStatsListener = db.collection(ColType.teacherStats)
          .where('teacherId', '==', this.state.teacher.id)
          .onSnapshot((querySnapshot) => {
            var docs = querySnapshot.docs
            if (docs.length > 0) {
              var teacherStats = flattenDoc(docs[0])
              var currentLevelObj = getLevelObj(teacherStats.exp)
              teacherStats.level = currentLevelObj.level
              teacherStats.levelName = currentLevelObj.levelName
              teacherStats.remainingExp = currentLevelObj.remainingExp
              teacherStats.nextLevelExp = currentLevelObj.nextLevelExp
              //console.log("teacher stats", teacherStats)
              this.setState({
                teacherStats: teacherStats,
              })
            }
            else {
              teacherStats = {
                level: 1,
                levelName: "Dot It Explorer",
                remainingExp: 0,
                nextLevelExp: 100,
              }
              this.setState({
                teacherStats: teacherStats,
              })
            }
          })

        var chatNotificationListener = db.collection(ColType.chatNotification)
          .doc(this.state.teacher.id)
          .onSnapshot((doc) => {
            if (!doc.exists) {
              return
            }
            let chatNotificationDict = flattenDoc(doc)

            this.setState({
              chatNotificationDict: chatNotificationDict,
              hasChatNotifications: doesChatNotificationDictHaveNotifications(chatNotificationDict, this.state.currentlyViewingChatId)
            })
          })

        var chatMessagesListener = db.collection(ColType.supportDesk)
          .where('uid', '==', this.state.user.uid)
          .orderBy('timeStamp', 'asc')
          .limit(30)
          .onSnapshot((querySnapshot) => {
            var chatMessages = []

            querySnapshot.forEach((doc) => {
              var chatMessage = flattenDoc(doc)
              chatMessages.push(chatMessage)
            })

            this.setState({
              chatMessages: chatMessages,
            })
          })

        var iepListener = db.collection(ColType.iep)
          .where('teacherId', '==', teacherId)
          .where('folder', '==', FOLDER_CURRENT)
          .orderBy('timeStamp', 'desc')
          .onSnapshot((querySnapshot) => {
            var IEPGoals = []
            var IEPDict = {}
            var IEPNumTypeDict = {}
            var studentIEPDict = {}
            var nonArchivedGoals = []
            var archivedGoals = []
            var studentRequiresAction = {}
            var studentsHighestTier = {}
            var studentsLevel = {}
            var studentOnTrack = {}
            var studentNumGoals = {}
            var totalOnTrack = { onTrack: 0, total: 0 }

            querySnapshot.forEach((doc) => {
              var iep = flattenDoc(doc)
              var latestMeasurementTimeStamp = moment.utc()

              if (!IEPNumTypeDict.hasOwnProperty(iep.studentId)) {
                IEPNumTypeDict[iep.studentId] = {
                  "IEP": 0,
                  "Tier 2": 0,
                  "Tier 3": 0,
                  "max": 0,
                }
              }
              if (iep.hasOwnProperty("isMTSS") && iep.isMTSS) {
                IEPNumTypeDict[iep.studentId][iep.iep.tier] += 1
              } else {
                IEPNumTypeDict[iep.studentId]["IEP"] += 1
              }
              var maxKeyVal = 0
              var maxKey = ""
              var numTypeObj = IEPNumTypeDict[iep.studentId]
              Object.keys(numTypeObj).map((key, idx) => {
                // skip max key
                if (key === "max") {
                  return false
                }
                var val = numTypeObj[key]
                if (val > maxKeyVal) {
                  maxKeyVal = val
                  maxKey = key
                }
                return false
              })
              // will never be empty string as it is initialized above to because at least one
              // value will be 1.
              numTypeObj["max"] = maxKey
              if (numTypeObj["max"] === "Tier 2" &&
                numTypeObj["Tier 2"] === numTypeObj["Tier 3"]) {
                numTypeObj["max"] = "Tier 3"
              }

              if (!studentNumGoals.hasOwnProperty(iep.studentId)) {
                studentNumGoals[iep.student.id] = 0
              } else {
                studentNumGoals[iep.student.id] += 1
              }
              if (iep &&
                iep.hasOwnProperty('latestMeasurementTimeStamp') &&
                iep.latestMeasurementTimeStamp &&
                iep.latestMeasurementTimeStamp.hasOwnProperty('seconds') &&
                iep.latestMeasurementTimeStamp.seconds) {
                latestMeasurementTimeStamp =
                  moment.utc(iep.latestMeasurementTimeStamp.seconds * 1000)
              }

              if (!iep.hasOwnProperty('isArchived') || !iep.isArchived) {
                var twoWeeksAfterLatestMeasurement =
                  moment.utc(latestMeasurementTimeStamp).add(2, 'weeks')
                var completionDate = moment.utc(iep.iep.completionDate, "MM/DD/YYYY")
                var sixWeeksToCompletionDate = moment.utc(completionDate).subtract(6, 'weeks')

                var requiresAction =
                  this.state.currentTimeStamp.isAfter(twoWeeksAfterLatestMeasurement)

                var completionDateRequiresAction =
                  this.state.currentTimeStamp.isAfter(sixWeeksToCompletionDate)

                var isOnTrack = iep.totalMeasurements === 0 ||
                  (iep.totalMeasurements > 0 && iep.onTrack)

                if (!studentOnTrack.hasOwnProperty(iep.studentId)) {
                  studentOnTrack[iep.studentId] = { onTrack: isOnTrack ? 1 : 0, total: 1 }
                  totalOnTrack.onTrack += (isOnTrack ? 1 : 0)
                  totalOnTrack.total += 1
                }
                else {
                  var currentOnTrack = studentOnTrack[iep.studentId]
                  if (isOnTrack) currentOnTrack.onTrack += 1
                  currentOnTrack.total += 1
                  studentOnTrack[iep.studentId] = currentOnTrack
                  totalOnTrack.onTrack += (isOnTrack ? 1 : 0)
                  totalOnTrack.total += 1
                }

                if (requiresAction) iep.requiresAction = true
                if (completionDateRequiresAction) iep.completionDateRequiresAction = true
                if (!(studentRequiresAction.hasOwnProperty(iep.studentId))) {
                  studentRequiresAction[iep.studentId] = {
                    newMeasurementNeeded: false,
                    completionDateUpcoming: false,
                  }
                }

                if (!studentRequiresAction[iep.studentId].newMeasurementNeeded) {
                  studentRequiresAction[iep.studentId].newMeasurementNeeded = requiresAction
                }
                if (!studentRequiresAction[iep.studentId].completionDateUpcoming) {
                  studentRequiresAction[iep.studentId]
                    .completionDateUpcoming = completionDateRequiresAction
                }

                if (!studentsHighestTier.hasOwnProperty(iep.studentId)) {
                  if (iep.hasOwnProperty("isMTSS") && iep.isMTSS) {
                    studentsHighestTier[iep.studentId] = iep.iep.tier
                  }
                  else {
                    studentsHighestTier[iep.studentId] = iep.iep.category
                  }
                }

                if (!studentsLevel.hasOwnProperty(iep.studentId)) {
                  if (iep.hasOwnProperty("isMTSS") && iep.isMTSS) {
                    studentsLevel[iep.studentId] = iep.iep.tier
                  }
                  else {
                    studentsLevel[iep.studentId] = iep.iep.level
                  }
                }
                else {
                  var level = studentsLevel[iep.studentId]
                  var newLevel = "N/A"
                  if (iep.hasOwnProperty("isMTSS") && iep.isMTSS) {
                    newLevel = iep.iep.tier
                  } else {
                    newLevel = iep.iep.level
                  }
                  var level3 = "70 and below"
                  var level2 = "70-79"
                  var level1 = "80 and above"
                  if (level === level1 && (newLevel === level2 || newLevel === level3)) {
                    studentsLevel[iep.studentId] = newLevel
                  }
                  else if (level === level2 && newLevel === level3) {
                    studentsLevel[iep.studentId] = newLevel
                  }
                  // iep is prioritized over mtss 
                  else if (!(level === level1 || level === level2 || level === level3)) {
                    studentsLevel[iep.studentId] = newLevel
                  }
                }
              }

              IEPGoals.push(iep)
              IEPDict[iep.id] = iep
            })

            // update calendar scheduled events with new iep calls 
            // if the calendar has already run once (i.e. this.state.IEPSscheduled)
            // has a value
            if (this.state.IEPScheduled) {
              var IEPScheduled = this.state.IEPScheduled

              IEPGoals.map((iep, index) => {
                IEPScheduled['total'].scheduledRequired += iep.iep.service.days
                return IEPScheduled[iep.id] = {
                  scheduledActual: 0,
                  scheduledRequired: iep.iep.service.days,
                }
              })

              this.state.events && this.state.events.map((event, index) => {
                return event.ieps.map((iepObj, index) => {
                  if (IEPScheduled.hasOwnProperty(iepObj.iepId)) {
                    IEPScheduled[iepObj.iepId].scheduledActual += 1
                    IEPScheduled['total'].scheduledActual += 1
                  }
                  return false
                })
              })

              this.setState({
                IEPScheduled: IEPScheduled,
              })
            }

            IEPGoals.sort((a, b) => {
              if (a.iep.mainSubject === b.iep.mainSubject) {
                return 1
              }
              return -1
            })
            // sort goals first
            IEPGoals.sort((a, b) => {
              let aIsMTSS = a.hasOwnProperty("isMTSS") && a.isMTSS
              let bIsMTSS = b.hasOwnProperty("isMTSS") && b.isMTSS
              if (!aIsMTSS && bIsMTSS) {
                return -1
              } else if (aIsMTSS && !bIsMTSS) {
                return 1
              } else if (aIsMTSS && bIsMTSS) {
                let aIsTier2 = a.iep.tier === "Tier 2"
                let bisTier2 = b.iep.tier === "Tier 2"
                if (aIsTier2 && !bisTier2) {
                  return -1
                } else if (!aIsTier2 && bisTier2) {
                  return 1
                } else {
                  return 0
                }
              }
              return 0
            })

            // after sort
            IEPGoals.map((iep, idx) => {
              if (!studentIEPDict.hasOwnProperty(iep.studentId)) {
                studentIEPDict[iep.studentId] = []
              }
              studentIEPDict[iep.studentId].push(iep)
              return false
            })


            // then add archivd an unarchived goals
            IEPGoals.map((iep, index) => {
              if (!iep.hasOwnProperty('isArchived') || !iep.isArchived) nonArchivedGoals.push(iep)
              else archivedGoals.push(iep)
              return false
            })

            this.setState({
              IEPGoals: IEPGoals,
              IEPDict: IEPDict,
              IEPNumTypeDict: IEPNumTypeDict,
              studentIEPDict: studentIEPDict,
              archivedGoals: archivedGoals,
              nonArchivedGoals: nonArchivedGoals,
              studentRequiresAction: studentRequiresAction,
              studentsHighestTier: studentsHighestTier,
              studentsLevel: studentsLevel,
              studentOnTrack: studentOnTrack,
              studentNumGoals: studentNumGoals,
              totalOnTrack: totalOnTrack
            }, () => {
              resolve()
            })
          })

        var snapshotListeners = this.state.snapshotListeners
        // if this ever happens, unsubscribe old listener before setting the new one
        if (snapshotListeners.hasOwnProperty('districtSettingsListener')) {
          snapshotListeners['districtSettingsListener']()
        }
        if (snapshotListeners.hasOwnProperty('teacherStatsListener')) {
          snapshotListeners['teacherStatsListener']()
        }
        if (snapshotListeners.hasOwnProperty('chatNotificationListener')) {
          snapshotListeners['chatNotificationListener']()
        }
        if (snapshotListeners.hasOwnProperty('chatMessagesListener')) {
          snapshotListeners['chatMessagesListener']()
        }
        if (snapshotListeners.hasOwnProperty('iepListener')) {
          snapshotListeners['iepListener']()
        }
        snapshotListeners['districtSettingsListener'] = districtSettingsListener
        snapshotListeners['teacherStatsListener'] = teacherStatsListener
        snapshotListeners['chatNotificationListener'] = chatNotificationListener
        snapshotListeners['chatMessagesListener'] = chatMessagesListener
        snapshotListeners['iepListener'] = iepListener
        this.setState({
          snapshotListeners: snapshotListeners
        })
      })
    }).then(() => {
      return new Promise((resolve, reject) => {
        var calendarEventsListener = db.collection(ColType.calendarEvents)
          .where('teacherId', '==', teacherId)
          .orderBy('startTime')
          .orderBy('index')
          .orderBy('duration', 'desc')
          .onSnapshot((querySnapshot) => {
            //console.log('calendar query', this.state.IEPGoals.length)
            var calendarEvents = []
            var calendarDict = {}
            var IEPScheduled = {
              total: {
                scheduledActual: 0,
                scheduledRequired: 0,
              }
            }
            var events = []
            var eventDict = {}
            var idx = 0

            this.state.IEPGoals.map((iep, index) => {
              IEPScheduled['total'].scheduledRequired += iep.iep.service.days
              return IEPScheduled[iep.id] = {
                scheduledActual: 0,
                scheduledRequired: iep.iep.service.days,
              }
            })

            querySnapshot.forEach((doc) => {
              if (!doc.exists) return
              var event = flattenDoc(doc)
              // store the doc for deletes
              event.doc = doc
              calendarEvents.push(event)
              var start = moment.utc(event.startTime.seconds * 1000)
              var end = new Date(event.endTime)
              //start = new Date(2015, 5, parseInt(event.day, 10),
              //  start.getHours(), start.getMinutes(), 0)
              end = new Date(2015, 5, parseInt(event.day, 10),
                end.getHours(), end.getMinutes(), 0)
              event.start = start
              event.end = end

              events.push(event)
              eventDict[event.id] = event
              calendarDict[idx.toString()] = {
                'flattenedDoc': event,
                'unFlattenedDoc': doc.data()
              }

              event.ieps.map((iepObj, index) => {
                if (IEPScheduled.hasOwnProperty(iepObj.iepId)) {
                  IEPScheduled[iepObj.iepId].scheduledActual += 1
                  IEPScheduled['total'].scheduledActual += 1
                }
                return false
              })

            })

            this.setState({
              calendarEvents: calendarEvents,
              calendarDict: calendarDict,
              events: events,
              eventDict: eventDict,
              IEPScheduled: IEPScheduled,
            }, () => {
              resolve()
            })
          })

        var snapshotListeners = this.state.snapshotListeners
        // if this ever happens, unsubscribe old listener before setting the new one
        if (snapshotListeners.hasOwnProperty('calendarEventsListener')) {
          snapshotListeners['calendarEventsListener']()
        }
        snapshotListeners['calendarEventsListener'] = calendarEventsListener
        this.setState({
          snapshotListeners: snapshotListeners
        })
      })
    }).then(() => {
      //console.log("Teacher", this.state.teacher)

      db.collection(ColType.school)
        .doc(this.state.teacher.schoolId)
        .get()
        .then((doc) => {
          var school = flattenDoc(doc)

          this.setState({
            school: school,
          })
        })

      const trySortSectionStudentIds = () => {
        if (!(this.state.students && this.state.studentDict && this.state.sections)) {
          return
        }

        let sections = this.state.sections
        for (let i = 0; i < sections.length; i++) {
          let section = sections[i]
          if (section.hasOwnProperty('studentIds') && section.studentIds) {
            section.studentIds.sort((studentIdA, studentIdB) => {
              if (!(this.state.studentDict.hasOwnProperty(studentIdA) &&
                this.state.studentDict.hasOwnProperty(studentIdB))) {
                return 0
              }
              let studentA = this.state.studentDict[studentIdA]
              let studentB = this.state.studentDict[studentIdB]
              if (!(studentA && studentB)) {
                return 0
              }
              if (!(studentA.hasOwnProperty('grade') &&
                studentB.hasOwnProperty('grade'))) {
                return 0
              }
              if (!(studentA.grade && studentB.grade)) {
                return 0
              }
              // If grade is Kindergarten, assign it a value of 0
              const gradeA = studentA.grade === "Kindergarten" ? 0 : parseInt(studentA.grade)
              const gradeB = studentB.grade === "Kindergarten" ? 0 : parseInt(studentB.grade)

              return gradeA - gradeB
            })
          }
        }

        this.setState({
          sections: sections,
        })
      }

      db.collection(ColType.sections)
        .where('teacherIds', 'array-contains', this.state.teacher.id)
        .where('districtId', '==', this.state.teacher.districtId)
        .get()
        .then((querySnapshot) => {
          let sections = []
          let sectionsDict = {}
          querySnapshot.forEach((doc) => {
            if (doc.exists) {
              var section = flattenDoc(doc)
              section.studentIdsDict = {}
              if (section.hasOwnProperty('studentIds') && section.studentIds) {
                section.studentIds.map((studentId, index) => {
                  section.studentIdsDict[studentId] = true
                  return false
                })
              }
              sections.push(section)
              sectionsDict[section.id] = section
            }
          })
          sections.sort((a, b) => {
            if (a.name > b.name) {
              return 1
            } else {
              return -1
            }
          })

          this.setState({
            sections: sections,
            sectionsDict: sectionsDict,
          }, () => {
            // will try to sort the section studentIds if sections and students are loaded
            trySortSectionStudentIds()
          })
        })

      const studentStateUpdateFunc = (querySnapshot) => {
        var students = []
        var studentDict = {}
        var grades = new Set()
        querySnapshot.forEach((doc) => {
          if (doc.exists) {
            var student = flattenDoc(doc)
            students.push(student)
            studentDict[doc.id] = student
            grades.add(student.grade)
          }
        })

        students.sort((a, b) => {
          var levelDifference = 0
          if (a.grade === 'K' && b.grade === 'K') {
            if (levelDifference !== 0) return levelDifference
            if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
              return 1
            } else {
              return -1
            }
          }
          if (a.grade === 'K' && b.grade !== 'K') return -1
          if (a.grade !== 'K' && b.grade === 'K') return 1
          if (a.grade === b.grade) {
            if (levelDifference !== 0) return levelDifference
            if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
              return 1
            } else {
              return -1
            }
          }
          var aGrade = parseInt(a.grade, 10)
          var bGrade = parseInt(b.grade, 10)
          if (aGrade > bGrade) return 1
          if (bGrade > aGrade) return -1
          return 0
        })

        grades = Array.from(grades).sort((a, b) => {
          if (a === 'K' && b === 'K') return 1
          if (a === 'K' && b !== 'K') return -1
          if (a !== 'K' && b === 'K') return 1
          a = parseInt(a, 10)
          b = parseInt(b, 10)
          if (a > b) return 1
          if (b > a) return -1
          return 0
        })

        this.setState({
          students: students,
          studentDict: studentDict,
          grades: grades,
        }, () => {
          // will try to sort the section studentIds if sections and students are loaded
          trySortSectionStudentIds()
        })
      }

      db.collection(ColType.student)
        .where('teacherIds', 'array-contains', teacherId)
        .where('folder', '==', FOLDER_CURRENT)
        .orderBy('grade', 'desc')
        .get()
        .then((querySnapshot) => {
          //console.log('initial students query', querySnapshot.empty)
          if (!querySnapshot.empty) {
            var studentListener = db.collection(ColType.student)
              .where('teacherIds', 'array-contains', teacherId)
              .where('folder', '==', FOLDER_CURRENT)
              .orderBy('grade', 'desc')
              .onSnapshot((querySnapshot) => {
                studentStateUpdateFunc(querySnapshot)
              })

            var snapshotListeners = this.state.snapshotListeners
            if (snapshotListeners.hasOwnProperty('studentListener')) {
              snapshotListeners['studentListener']()
            }
            snapshotListeners['studentListener'] = studentListener
            this.setState({
              snapshotListeners: snapshotListeners
            })
          } else {
            var studentListener = db.collection(ColType.student)
              .where('teacherId', '==', teacherId)
              .where('folder', '==', FOLDER_CURRENT)
              .orderBy('grade', 'desc')
              .onSnapshot((querySnapshot) => {
                studentStateUpdateFunc(querySnapshot)
              })

            var snapshotListeners = this.state.snapshotListeners
            if (snapshotListeners.hasOwnProperty('studentListener')) {
              snapshotListeners['studentListener']()
            }
            snapshotListeners['studentListener'] = studentListener
            this.setState({
              snapshotListeners: snapshotListeners
            })
          }
        })

      var uploadedFilesListener = db.collection(ColType.uploadedfiles)
        .where('teacherId', '==', teacherId)
        .orderBy('timeStamp', 'desc')
        .onSnapshot((querySnapshot) => {
          var uploadedFiles = []
          var uploadedFilesDict = {}
          var studentUploadedFilesDict = {}
          var uploadedFilesFileNameDict = {}
          querySnapshot.forEach((doc) => {
            if (doc.exists) {
              var uploadedFile = flattenDoc(doc)
              var uploadedFileId = getUploadedFileId(uploadedFile)
              // only add for valid uploaded file ids
              if (uploadedFileId) {
                uploadedFiles.push(uploadedFile)
                uploadedFilesFileNameDict[uploadedFile.fileName] = uploadedFile
                uploadedFilesDict[uploadedFileId] = uploadedFile
                if (!studentUploadedFilesDict.hasOwnProperty(uploadedFile.studentId)) {
                  studentUploadedFilesDict[uploadedFile.studentId] = []
                }
                studentUploadedFilesDict[uploadedFile.studentId].push(uploadedFile)
              }
            }
          })

          this.setState({
            uploadedFiles: uploadedFiles,
            uploadedFilesFileNameDict: uploadedFilesFileNameDict,
            uploadedFilesDict: uploadedFilesDict,
            studentUploadedFilesDict: studentUploadedFilesDict,
          })
        })

      // only retrieve drafts that are after the last standards
      // db update, as ids may be incorrect if a draft for an old
      // version of standards is used.
      let lastStandardsUpdateDate = new Date('16 March 2022 19:00:00')
      let iepDraftListener = db.collection(ColType.iepDrafts)
        .where('teacherId', '==', teacherId)
        .where('timeStamp', '>', lastStandardsUpdateDate)
        .orderBy('timeStamp', 'desc')
        .limit(10)
        .onSnapshot((querySnapshot) => {
          var iepDrafts = []
          var iepDraftDict = {}

          querySnapshot.forEach((doc) => {
            if (doc.exists) {
              var iepDraft = flattenDoc(doc)
              iepDrafts.push(iepDraft)
              iepDraftDict[iepDraft.id] = iepDraft
            }
          })

          this.setState({
            iepDrafts: iepDrafts,
            iepDraftDict: iepDraftDict,
          })
        })

      let pepDraftListener = db.collection(ColType.pepDrafts)
        .where('teacherId', '==', teacherId)
        .where('timeStamp', '>', lastStandardsUpdateDate)
        .orderBy('timeStamp', 'desc')
        .limit(10)
        .onSnapshot((querySnapshot) => {
          var pepDrafts = []
          var pepDraftDict = {}

          querySnapshot.forEach((doc) => {
            if (doc.exists) {
              var pepDraft = flattenDoc(doc)
              pepDrafts.push(pepDraft)
              pepDraftDict[pepDraft.id] = pepDraft
            }
          })

          this.setState({
            pepDrafts: pepDrafts,
            pepDraftDict: pepDraftDict,
          })
        })

      let generalNotificationsListener = db.collection(ColType.generalNotifications)
        .where('teacherId', '==', teacherId)
        .orderBy('timeStamp', 'desc')
        .limit(15)
        .onSnapshot((querySnapshot) => {
          let generalNotifications = []

          querySnapshot.forEach((doc) => {
            if (doc.exists) {
              let generalNotification = flattenDoc(doc)
              generalNotifications.push(generalNotification)
            }
          })

          let generalNotificationsNumUnvisited = 0
          // only count if the first node is not visited, the first node is set to visited
          // when the notifications dropdown is opened
          if (generalNotifications.length > 0) {
            if (generalNotifications[0].hasOwnProperty('visited') && !generalNotifications[0].visited) {
              for (let notification of generalNotifications) {
                // found the last first notification whos visited property was modified to true
                // stop here
                if (notification.hasOwnProperty('visited') && notification.visited) {
                  break
                }
                if (notification.hasOwnProperty('visited') && !notification.visited) {
                  generalNotificationsNumUnvisited += 1
                }
              }
            }
          }

          this.setState({
            generalNotifications: generalNotifications,
            generalNotificationsNumUnvisited: generalNotificationsNumUnvisited,
          })
        })


      var snapshotListeners = this.state.snapshotListeners
      // if this ever happens, unsubscribe old listener before setting the new one

      if (snapshotListeners.hasOwnProperty('uploadedFilesListener')) {
        snapshotListeners['uploadedFilesListener']()
      }
      snapshotListeners['uploadedFilesListener'] = uploadedFilesListener

      if (snapshotListeners.hasOwnProperty('iepDraftListener')) {
        snapshotListeners['iepDraftListener']()
      }
      snapshotListeners['iepDraftListener'] = iepDraftListener

      if (snapshotListeners.hasOwnProperty('pepDraftListener')) {
        snapshotListeners['pepDraftListener']()
      }
      snapshotListeners['pepDraftListener'] = pepDraftListener

      if (snapshotListeners.hasOwnProperty('generalNotificationsListener')) {
        snapshotListeners['generalNotificationsListener']()
      }
      snapshotListeners['generalNotificationsListener'] = generalNotificationsListener

      this.setState({
        snapshotListeners: snapshotListeners
      })
    })
      .catch((e) => {
        console.log(e)
      })
  }

  componentWillUnmount() {
    // unsubscribe listeners
    if (this.state.snapshotListeners.hasOwnProperty('authListener')) {
      this.state.snapshotListeners['authListener']()
    }
    if (this.state.snapshotListeners.hasOwnProperty('userListener')) {
      this.state.snapshotListeners['userListener']()
    }
    if (this.state.snapshotListeners.hasOwnProperty('teacherStatsListener')) {
      this.state.snapshotListeners['teacherStatsListener']()
    }
    if (this.state.snapshotListeners.hasOwnProperty('chatNotificationListener')) {
      this.state.snapshotListeners['chatNotificationListener']()
    }
    if (this.state.snapshotListeners.hasOwnProperty('chatMessagesListener')) {
      this.state.snapshotListeners['chatMessagesListener']()
    }
    if (this.state.snapshotListeners.hasOwnProperty('iepListener')) {
      this.state.snapshotListeners['iepListener']()
    }
    if (this.state.snapshotListeners.hasOwnProperty('calendarEventsListener')) {
      this.state.snapshotListeners['calendarEventsListener']()
    }
    if (this.state.snapshotListeners.hasOwnProperty('studentListener')) {
      this.state.snapshotListeners['studentListener']()
    }
    if (this.state.snapshotListeners.hasOwnProperty('uploadedFilesListener')) {
      this.state.snapshotListeners['uploadedFilesListener']()
    }
    if (this.state.snapshotListeners.hasOwnProperty('iepDraftListener')) {
      this.state.snapshotListeners['iepDraftListener']()
    }
    if (this.state.snapshotListeners.hasOwnProperty('pepDraftListener')) {
      this.state.snapshotListeners['pepDraftListener']()
    }
    if (this.state.snapshotListeners.hasOwnProperty('generalNotificationsListener')) {
      this.state.snapshotListeners['generalNotificationsListener']()
    }
    if (this.state.snapshotListeners.hasOwnProperty('districtSettingsListener')) {
      this.state.snapshotListeners['districtSettingsListener']()
    }
  }

  componentWillReceiveProps(props, newProps) {
    var split = getURLSplitArray(window.location)
    var pathId = split[split.length - 2]

    this.setState({
      pathId: pathId,
    })
  }

  componentWillUpdate(nextProps, nextState) {
    return true
  }

  render() {
    const { match: { url } } = this.props
    //console.log(this.state)

    return (
      <Layout className={this.state.readOnly ? 'read-only' : ''}>
        {this.state.readOnly && this.state.adminBaseProps ?
          <AdminHeader
            person={this.state.adminBaseProps.admin}
            schools={this.state.adminBaseProps.schools}
            selectedKey={this.state.pathId}
            history={this.props.history}
          >
          </AdminHeader>
          : ''}

        {this.state.readOnly && this.state.schoolAdminBaseProps ?
          <SchoolAdminHeader
            person={this.state.schoolAdminBaseProps.schoolAdmin}
            selectedKey={this.state.pathId}
            history={this.props.history}
          >
          </SchoolAdminHeader>
          : ''}

        {this.state.readOnly && this.state.instructionalFacilitatorBaseProps ?
          <InstructionalFacilitatorHeader
            person={this.state.instructionalFacilitatorBaseProps.instructionalFacilitator}
            selectedKey={this.state.pathId}
            history={this.props.history}
          >
          </InstructionalFacilitatorHeader>
          : ''}

        {!this.state.teacher ?
          <TeacherHeaderInitial
            readOnly={this.state.readOnly}
            currentTimeStamp={this.state.currentTimeStamp}
            teacherBaseProps={this.state}
            user={this.state.user}
            person={this.state.teacher}
            students={this.state.students}
            studentDict={this.state.studentDict}
            selectedKey={this.state.pathId}
            history={this.props.history}
            positionNotFixed={false}
          />
          :
          this.state.teacher &&
            this.state.teacher.hasOwnProperty('isCustomForm') &&
            this.state.teacher.isCustomForm ?
            <TeacherHeaderForms
              readOnly={this.state.readOnly}
              currentTimeStamp={this.state.currentTimeStamp}
              teacherBaseProps={this.state}
              user={this.state.user}
              person={this.state.teacher}
              students={this.state.students}
              studentDict={this.state.studentDict}
              selectedKey={this.state.pathId}
              history={this.props.history}
              positionNotFixed={false}
            />
            :
            <TeacherHeader
              readOnly={this.state.readOnly}
              currentTimeStamp={this.state.currentTimeStamp}
              teacherBaseProps={this.state}
              user={this.state.user}
              person={this.state.teacher}
              students={this.state.students}
              studentDict={this.state.studentDict}
              selectedKey={this.state.pathId}
              history={this.props.history}
              positionNotFixed={false}
            >
            </TeacherHeader>
        }

        <Route path={`${url}/home/*`} render={props => TeacherHomeComponent(props, this.state)} />
        <Route path={`${url}/component-test/*`} render={props => TeacherEdvizComponentTestComponent(props, this.state)} />
        <Route path={`${url}/plc/`} render={props => TeacherPLCComponent(props, this.state)} />
        <Route path={`${url}/meetings/`} render={props => TeacherMeetingsComponent(props, this.state)} />
        <Route path={`${url}/pacing-guide/`} render={props => PacingGuideComponent(props, this.state)} />
        <Route path={`${url}/pacing-guide-lesson-plan/*`} render={props => PacingGuideLessonPlanComponent(props, this.state)} />
        <Route path={`${url}/pacing-guide-clever-quiz/*`} render={props => PacingGuideCleverTestComponent(props, this.state)} />
        <Route path={`${url}/tier-one/*`} render={props => TierOneHomeComponent(props, this.state)} />
        <Route path={`${url}/tier-one-plan/*`} render={props => TierOnePlanComponent(props, this.state)} />

        <Route path={`${url}/add-student-home/*`} render={props => TeacherAddStudentHomeComponent(props, this.state)} />
        <Route path={`${url}/add-student/*`} render={props => TeacherTemporaryAddStudentComponent(props, this.state)} />
        <Route path={`${url}/add-student-from-csv/*`} render={props => AddStudentCSVComponent(props, this.state)} />
        <Route path={`${url}/add-students-from-clever/*`} render={props => TeacherAddStudentsCleverHomeComponent(props, this.state)} />

        <Route path={`${url}/chat/*`}
          render={props => TeacherChatHomeComponent(props, this.state)} />

        <Route path={`${url}/help-desk/*`}
          render={props => TeacherHelpDeskComponent(props, this.state)} />
        <Route path={`${url}/help-desk-getting-started/*`}
          render={props => TeacherHelpDeskGettingStartedComponent(props, this.state)} />
        <Route path={`${url}/help-desk-adding-a-student/*`}
          render={props => TeacherHelpDeskAddingAStudentComponent(props, this.state)} />

        <Route path={`${url}/help-desk-add-iep-student/*`}
          render={props => TeacherHelpDeskAddIEPStudentComponent(props, this.state)} />
        <Route path={`${url}/help-desk-add-iep-information/*`}
          render={props => TeacherHelpDeskAddIEPInformationComponent(props, this.state)} />
        <Route path={`${url}/help-desk-add-iep-select/*`}
          render={props => TeacherHelpDeskAddIEPSelectComponent(props, this.state)} />
        <Route path={`${url}/help-desk-add-iep-modify/*`}
          render={props => TeacherHelpDeskAddIEPModifyComponent(props, this.state)} />
        <Route path={`${url}/help-desk-add-iep-progress-monitoring/*`}
          render={props => TeacherHelpDeskAddIEPProgressMonitoringComponent(props, this.state)} />
        <Route path={`${url}/help-desk-add-iep-service/*`}
          render={props => TeacherHelpDeskAddIEPServiceComponent(props, this.state)} />
        <Route path={`${url}/help-desk-add-iep-accommodations/*`}
          render={props => TeacherHelpDeskAddIEPAccommodationsComponent(props, this.state)} />
        <Route path={`${url}/help-desk-add-iep-confirm/*`}
          render={props => TeacherHelpDeskAddIEPConfirmComponent(props, this.state)} />

        <Route path={`${url}/subscribe/*`}
          render={props => CreatePaidAccountComponent(props, this.state)} />
        <Route path={`${url}/class/*`} render={props => TeacherClassComponent(props, this.state)} />
        <Route path={`${url}/my-reports/*`}
          render={props => TeacherReportsBaseComponent(props, this.state)} />
        <Route path={`${url}/contact-us/*`}
          render={props => TeacherContactUsComponent(props, this.state)} />
        <Route path={`${url}/help/*`}
          render={props => TeacherNeedHelpComponent(props, this.state)} />
        <Route path={`${url}/all-records/*`}
          render={props => TeacherRecordsComponent(props, this.state)} />
        <Route path={`${url}/reports/*`}
          render={props => TeacherReportsComponent(props, this.state)} />
        <Route path={`${url}/uploads/*`}
          render={props => TeacherStudentUploadSelectComponent(props, this.state)} />
        <Route path={`${url}/student-uploads/*`}
          render={props => TeacherStudentUploadViewComponent(props, this.state)} />
        <Route path={`${url}/student-upload-view/*`}
          render={props => TeacherStudentUploadFileViewComponent(props, this.state)} />
        <Route path={`${url}/records/*`}
          render={props => TeacherStudentRecordsComponent(props, this.state)} />
        <Route path={`${url}/student/*`}
          render={props => TeacherStudentViewComponent(props, this.state)} />
        <Route path={`${url}/student-plan/*`}
          render={props => TeacherStudentPlanComponent(props, this.state)} />
        <Route path={`${url}/edit-student/*`}
          render={props => TeacherUpdateStudentComponent(props, this.state)} />
        <Route path={`${url}/schedule/*`}
          render={props => TeacherSchedulerComponent(props, this.state)} />
        <Route path={`${url}/progress-monitoring/*`}
          render={props => TeacherProgressMonitoringComponent(props, this.state)} />
        <Route path={`${url}/student-report/*`}
          render={props => TeacherStudentReportComponent(props, this.state)} />
        <Route path={`${url}/groups/*`}
          render={props => TeacherGroupReportsComponent(props, this.state)} />
        <Route path={`${url}/lesson-plan/*`}
          render={props => TeacherGroupLessonPlanComponent(props, this.state)} />
        <Route path={`${url}/add-goal-start/*`}
          render={props => TeacherAddIEPStartComponent(props, this.state)} />
        <Route path={`${url}/add-goal-student/*`}
          render={props => TeacherAddIEPStudentComponent(props, this.state)} />
        <Route path={`${url}/add-goal-information/*`}
          render={props => TeacherAddIEPInformationComponent(props, this.state)} />
        <Route path={`${url}/add-goal-select/*`}
          render={props => TeacherAddIEPSelectComponent(props, this.state)} />
        <Route path={`${url}/add-goal-modify/*`}
          render={props => TeacherAddIEPModifyComponent(props, this.state)} />
        <Route path={`${url}/add-goal-progress-monitoring/*`}
          render={props => TeacherAddIEPProgressMonitoringComponent(props, this.state)} />
        <Route path={`${url}/add-goal-service/*`}
          render={props => TeacherAddIEPServiceComponent(props, this.state)} />
        <Route path={`${url}/add-goal-accommodations/*`}
          render={props => TeacherAddIEPAccommodationsComponent(props, this.state)} />
        <Route path={`${url}/add-goal-confirm/*`}
          render={props => TeacherAddIEPConfirmComponent(props, this.state)} />

        <Route path={`${url}/mtss-add-goal-student/*`}
          render={props => PEPTeacherAddIEPStudentComponent(props, this.state)} />
        <Route path={`${url}/mtss-add-goal-information/*`}
          render={props => PEPTeacherAddIEPInformationComponent(props, this.state)} />
        <Route path={`${url}/mtss-add-goal-select/*`}
          render={props => PEPTeacherAddIEPSelectComponent(props, this.state)} />
        <Route path={`${url}/mtss-add-goal-modify/*`}
          render={props => PEPTeacherAddIEPModifyComponent(props, this.state)} />
        <Route path={`${url}/mtss-add-goal-progress-monitoring/*`}
          render={props => PEPTeacherAddIEPProgressMonitoringComponent(props, this.state)} />
        <Route path={`${url}/mtss-add-goal-service/*`}
          render={props => PEPTeacherAddIEPServiceComponent(props, this.state)} />
        <Route path={`${url}/mtss-add-goal-accommodations/*`}
          render={props => PEPTeacherAddIEPAccommodationsComponent(props, this.state)} />
        <Route path={`${url}/mtss-add-goal-confirm/*`}
          render={props => PEPTeacherAddIEPConfirmComponent(props, this.state)} />

        <Route path={`${url}/california-form/*`}
          render={props => FormHomeComponent(props, this.state)} />
        <Route path={`${url}/california-form-student/*`}
          render={props => FormStudentComponent(props, this.state)} />
        <Route path={`${url}/california-form-step-one/*`}
          render={props => FormPartOneComponent(props, this.state)} />
        <Route path={`${url}/california-form-step-two/*`}
          render={props => FormPartTwoComponent(props, this.state)} />

        <Route path={`${url}/archived`}
          render={props => TeacherArchivedBaseComponent(props, this.state)} />
        <Route path={`${url}/transfers`}
          render={props => TeacherTransferBaseComponent(props, this.state)} />
        <Route path={`${url}/deleted`}
          render={props => TeacherDeletedBaseComponent(props, this.state)} />
        <Route path={`${url}/quizzes`} render={props => PacingGuideTeacherAssessmentsBaseComponent(props, this.state)} />
      </Layout>
    )
  }
}

export default TeacherBase