import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import PersonAvatar from '../customcomponents/PersonAvatar'
import { Icon, Modal } from 'antd'
import { getColorGradient, getDOKLevelFromGrade } from '../Util'
import { Document } from '../teacher/edvizv2/Edviz'
import DocumentDelegator from '../edviz/EdvizDelegator'


class DOKStandardBreakdown extends Component {
    state = {
        studentDict: {},
        questionDataDict: {},
        questionData: [],
        questionsMostIncorrect: [],
        questionsMostCorrect: [],
        studentsCompleted: 0,
        visible: false,
        viewingLessonPlanItem: null,
        viewingQuestionIdx: 0
    }

    componentDidMount() {
        this.getStats()
    }

    componentWillReceiveProps(nextProps) {
        this.getStats()
    }

    showModal = (lessonPlanItem, questionIdx) => {
        this.setState({
            visible: true,
            viewingLessonPlanItem: lessonPlanItem,
            viewingQuestionIdx: questionIdx,
        })
    }

    hideModal = () => {
        this.setState({
            visible: false,
        })
    }

    studentDictHasAllStudents = (quiz) => {
        const studentIds = Object.keys(quiz.studentGrades)
        for (let i = 0; i < studentIds.length; i++) {
            const studentId = studentIds[i]
            if (!this.props.studentDict.hasOwnProperty(studentId)) {
                return false
            }
        }
        return true
    }

    areChosenAnswersCorrect = (question, chosenAnswer) => {
        const questionAnswers = question.components[0].answers
        for (let answer of chosenAnswer.answer) {
            if (!questionAnswers[answer].correct) {
                return false
            }
        }
        return true
    }

    isChosenAnswerCorrect = (questionDoc, chosenAnswersBaseQuiz) => {
        const uuid = questionDoc.metadata.uuid
        if (!chosenAnswersBaseQuiz.hasOwnProperty(uuid)) {
            return false
        }
        const chosenAnswerBaseQuestionDocId = chosenAnswersBaseQuiz[uuid]
        for (let i = 0; i < questionDoc.components[0].answers.length; i++) {
            const answer = questionDoc.components[0].answers[i]
            if (answer.correct && answer.id === chosenAnswerBaseQuestionDocId) {
                return true
            }
        }
        return false
    }

    getStudentsInDOKLevel = (quiz, dokLevel) => {
        let students = []
        let studentsDict = {}
        if (!(quiz && dokLevel)) {
            return students
        }
        if (quiz.quiz.docs.length === 0) {
            return students
        }
        let count = 0
        Object.keys(quiz.studentGrades).map((studentId) => {
            if (!quiz.studentGrades.hasOwnProperty(studentId)) {
                return false
            }
            const studentGrade = quiz.studentGrades[studentId]
            let assessmentCompleted = studentGrade.graded
            let highestDOKLevel = 1
            if (assessmentCompleted) {
                const finalGrade = studentGrade.finalGrade.grade * 100
                highestDOKLevel = getDOKLevelFromGrade(finalGrade)
            }
            if (highestDOKLevel !== dokLevel) {
                return false
            }
            if (highestDOKLevel === dokLevel && assessmentCompleted) {
                count += 1
                if (!studentsDict.hasOwnProperty(studentId)) {
                    studentsDict[studentId] = true
                    students.push(studentId)
                }
            }
            // dokLevel1 if not completed
            if (!assessmentCompleted && dokLevel === 1) {
                count += 1
                if (!studentsDict.hasOwnProperty(studentId)) {
                    studentsDict[studentId] = true
                    students.push(studentId)
                }
            }
            return false
        })

        return students
    }

    getAverageForQuestionUUID = (quiz, questionUUID) => {
        let students = []
        let studentsDict = {}
        if (!(quiz)) {
            return students
        }
        if (quiz.quiz.docs.length === 0) {
            return students
        }
        let correctCount = 0
        let count = 0
        Object.keys(quiz.studentGrades).map((studentId) => {
            if (!quiz.studentGrades.hasOwnProperty(studentId)) {
                return false
            }
            const studentGrade = quiz.studentGrades[studentId]
            let assessmentCompleted = studentGrade.graded
            let highestDOKLevel = 1
            if (assessmentCompleted) {
                const finalGrade = studentGrade.finalGrade.grade * 100
                const chosenAnswersBaseQuiz = studentGrade.chosenAnswersBaseQuiz
                for (let i = 0; i < quiz.quiz.docs.length; i++) {
                    const questionDoc = quiz.quiz.docs[i]
                    const dokLevel = questionDoc.metadata.dokLevel
                    const uuid = questionDoc.metadata.uuid
                    if (uuid !== questionUUID) {
                        continue
                    }
                    if (!chosenAnswersBaseQuiz.hasOwnProperty(uuid)) {
                        continue
                    }
                    const correct = this.isChosenAnswerCorrect(questionDoc, chosenAnswersBaseQuiz)
                    if (correct) {
                        correctCount += 1
                    }
                    count += 1
                }
            }
            return false
        })

        if (correctCount === 0 || count === 0) {
            return '0.00'
        }
        return ((correctCount / count) * 100).toFixed(2)
    }

    getAverageForQuiz = (quiz) => {
        let students = []
        let studentsDict = {}
        if (!(quiz)) {
            return students
        }
        if (quiz.quiz.docs.length === 0) {
            return students
        }
        let correctCount = 0
        let count = 0
        Object.keys(quiz.studentGrades).map((studentId) => {
            if (!quiz.studentGrades.hasOwnProperty(studentId)) {
                return false
            }
            const studentGrade = quiz.studentGrades[studentId]
            let assessmentCompleted = studentGrade.graded
            let highestDOKLevel = 1
            if (assessmentCompleted) {
                const finalGrade = studentGrade.finalGrade.grade * 100
                const chosenAnswersBaseQuiz = studentGrade.chosenAnswersBaseQuiz
                for (let i = 0; i < quiz.quiz.docs.length; i++) {
                    const questionDoc = quiz.quiz.docs[i]
                    const dokLevel = questionDoc.metadata.dokLevel
                    const uuid = questionDoc.metadata.uuid
                    if (!chosenAnswersBaseQuiz.hasOwnProperty(uuid)) {
                        continue
                    }
                    const correct = this.isChosenAnswerCorrect(questionDoc, chosenAnswersBaseQuiz)
                    if (correct) {
                        correctCount += 1
                    }
                    count += 1
                }
            }
            return false
        })

        if (correctCount === 0 || count === 0) {
            return '0.00'
        }
        return ((correctCount / count) * 100).toFixed(2)
    }

    getStats = () => {
        const quiz = this.props.quiz
        if (!quiz) {
            return
        }
        if (quiz.quiz.docs.length === 0) {
            return
        }
        if (!(this.props &&
            this.props.studentDict)) {
            return
        }
        //console.log('quiz', quiz)
        /*if (this.state.statsRetrieved) {
            return
        }
    
        this.setState({ statsRetrieved: true })*/

        let stats = {
            questionDataDict: {},
            questionData: [],
            questionsMostIncorrect: [],
            questionsMostCorrect: [],
            studentsCompleted: 0,
        }

        Object.keys(quiz.studentGrades).map((studentId) => {
            if (!this.props.studentDict.hasOwnProperty(studentId)) {
                return false
            }
            if (!quiz.studentGrades.hasOwnProperty(studentId)) {
                return false
            }
            const student = this.props.studentDict[studentId]
            const studentGrade = quiz.studentGrades[studentId]
            let assessmentCompleted = studentGrade.graded
            let highestDOKLevel = 1
            if (assessmentCompleted) {
                stats.studentsCompleted += 1
                const chosenAnswersBaseQuiz = studentGrade.chosenAnswersBaseQuiz
                for (let i = 0; i < quiz.quiz.docs.length; i++) {
                    const questionDoc = quiz.quiz.docs[i]
                    const dokLevel = questionDoc.metadata.dokLevel
                    const uuid = questionDoc.metadata.uuid
                    if (!chosenAnswersBaseQuiz.hasOwnProperty(uuid)) {
                        continue
                    }
                    const correct = this.isChosenAnswerCorrect(questionDoc, chosenAnswersBaseQuiz)
                    if (correct) {
                        const dokLevelNumber = parseInt(dokLevel, 10)
                        if (dokLevelNumber > highestDOKLevel) {
                            highestDOKLevel = dokLevelNumber
                        }
                    }

                    if (!stats.questionDataDict.hasOwnProperty(i)) {
                        stats.questionDataDict[i] = {
                            correct: 0,
                            incorrect: 0,
                            total: 0,
                        }
                    }
                    if (correct) {
                        stats.questionDataDict[i].correct += 1
                    } else {
                        stats.questionDataDict[i].incorrect += 1
                    }
                    stats.questionDataDict[i].total += 1
                }
            }
        })

        for (let i = 0; i < quiz.quiz.docs.length; i++) {
            if (!stats.questionDataDict.hasOwnProperty(i)) {
                stats.questionData.push({
                    questionIndex: i,
                    correct: 0,
                    incorrect: 0,
                    total: 0,
                })
                continue
            }
            const questionData = stats.questionDataDict[i]
            stats.questionData.push({
                questionIndex: i,
                correct: questionData.correct,
                incorrect: questionData.incorrect,
                total: questionData.total,
            })
        }

        stats.questionsMostIncorrect = Object.assign([], stats.questionData).sort((a, b) => {
            return b.incorrect - a.incorrect
        })
        stats.questionsMostCorrect = Object.assign([], stats.questionData).sort((a, b) => {
            return b.correct - a.correct
        })

        this.setState({
            stats: stats,
            statsCompleted: true,
        })
    }

    getStudentView = (studentId) => {
        if (!(this.props.quiz &&
            this.props.studentDict &&
            studentId &&
            this.props.studentDict.hasOwnProperty(studentId))) {
            return false
        }
        const quiz = this.props.quiz
        const student = this.props.studentDict[studentId]
        if (!(student && quiz)) {
            return false
        }
        if (quiz.quiz.docs.length === 0) {
            return false
        }
        if (!quiz.studentGrades.hasOwnProperty(student.id)) {
            return false
        }
        const studentGrade = quiz.studentGrades[student.id]
        let assessmentCompleted = studentGrade.graded

        if (this.props.hasOwnProperty('onlyShowCompleted') &&
            this.props.onlyShowCompleted &&
            !assessmentCompleted) {
            return false
        }

        return <tr key={'tr-' + student.id}>
            <td className='sticky-left sticky-left-shadow' style={{ border: '1px solid rgb(217, 217, 217)', background: '#f1f1f1' }}>
                <PersonAvatar
                    person={student}
                    size={'large'}
                    containerClassName="font-16"
                    avatarClassName="font-16 font-bold mr-2"
                    personClassName="font-black font-16 ellipsis"
                    personStyle={{ maxWidth: '220px' }}
                />
            </td>
            {this.props.hasOwnProperty('showProgress') && this.props.showProgress ?
                <td style={{ border: '1px solid #d9d9d9' }}>
                    {assessmentCompleted ?
                        <div className='text-success font-bold'>
                            Completed
                        </div> :
                        <div className='text-blue font-bold'>In progress</div>
                    }
                </td> : ''}
            {quiz.quiz.docs.map((lessonPlanItem, idx) => {
                let allCorrect = false
                if (assessmentCompleted) {
                    let studentGrade = quiz.studentGrades[student.id]
                    if (studentGrade.hasOwnProperty('chosenAnswersBaseQuiz')) {
                        allCorrect = this.isChosenAnswerCorrect(lessonPlanItem, studentGrade.chosenAnswersBaseQuiz)
                    }
                }
                let borderClasses = ' border-right border-top border-bottom'
                if (idx === 0) {
                    borderClasses += ' border-left br-tl-4 br-bl-4'
                } else if (idx === quiz.quiz.docs.length - 1) {
                    borderClasses += ' br-tr-4 br-br-4'
                }
                return <td className={'results-table-question-cell purple-border-hover cursor-pointer'}
                    style={{
                        backgroundColor: assessmentCompleted ? allCorrect ? '#fff' : '#ffccc7' : '#fff',
                        border: '1px solid #d9d9d9'
                    }}
                    key={'assessment-' + student.id + idx}
                    onClick={(e) => {
                        this.showModal(lessonPlanItem, idx)
                    }}
                    title='Click to view question'>
                    <div className='flex flex-v-center flex-h-center'>
                        {assessmentCompleted ?
                            allCorrect ?
                                <Icon type='check' className='font-bold' style={{ color: '#389e0d' }} /> :
                                <Icon type='close' className='font-bold' style={{ color: '#cf1322' }} /> :
                            <div className='text-center' style={{ width: '20px' }}>-</div>}
                        {/*<div className='ml-1'>{question.metadata.standard.standard}</div>*/}
                    </div>
                </td>
            })}
            <td className='sticky-right sticky-right-shadow text-center'
                style={{ border: '1px solid rgb(217, 217, 217)', borderRight: 'none', background: '#f1f1f1' }}>
                {assessmentCompleted ? (quiz.studentGrades[student.id].finalGrade.grade * 100).toFixed(2) + '%' : '-'}
            </td>
        </tr>
    }

    sortDOKStudents = (studentIds) => {
        if (!this.props.quiz) {
            return studentIds
        }

        return studentIds.sort((a, b) => {
            if (!this.props.quiz.studentGrades.hasOwnProperty(a) ||
                !this.props.quiz.studentGrades.hasOwnProperty(b)) {
                return 0
            }

            const studentAGrade = this.props.quiz.studentGrades[a].finalGrade.grade
            const studentBGrade = this.props.quiz.studentGrades[b].finalGrade.grade
            return studentAGrade - studentBGrade
        })
    }

    render() {
        let dok1Students = []
        let dok2Students = []
        let dok3Students = []
        if (this.props.quiz) {
            dok1Students = this.sortDOKStudents(this.getStudentsInDOKLevel(this.props.quiz, 1))
            dok2Students = this.sortDOKStudents(this.getStudentsInDOKLevel(this.props.quiz, 2))
            dok3Students = this.sortDOKStudents(this.getStudentsInDOKLevel(this.props.quiz, 3))
        }
        return (
            <div className='relative'>
                {this.props.quiz ?
                    <div>
                        <table className='w-100 font-16 results-table'>
                            <thead>
                                <tr>
                                    <th className='sticky-left pl-2 background-fff font-24'>Class Report</th>
                                    {this.props.hasOwnProperty('showProgress') && this.props.showProgress ?
                                        <th>Progress</th>
                                        : ''}
                                    {this.props.quiz.quiz.docs.map((lessonPlanItem, idx) => {
                                        return <th className='results-table-question-cell cursor-pointer'
                                            onClick={(e) => {
                                                this.showModal(lessonPlanItem, idx)
                                            }}
                                            title='Click to view question'>
                                            <div>
                                                <div className='font-14 font-normal text-muted'>QUESTION {idx + 1}</div>
                                                <div>{lessonPlanItem.metadata.standard.standard}</div>
                                            </div>
                                        </th>
                                    })}
                                    <th className='sticky-right' style={{ paddingLeft: '22px' }}>Grade</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='sticky-left sticky-left-shadow' style={{ border: '1px solid #d9d9d9', background: '#e4e4e4' }}>Average</td>
                                    {this.props.hasOwnProperty('showProgress') && this.props.showProgress ?
                                        <td style={{ border: '1px solid rgb(217, 217, 217)' }}></td>
                                        : ''}
                                    {this.props.quiz.quiz.docs.map((lessonPlanItem, idx) => {
                                        const avgStr = this.getAverageForQuestionUUID(this.props.quiz, lessonPlanItem.metadata.uuid)
                                        const avgNum = parseFloat(avgStr)
                                        return <td className='purple-border-hover cursor-pointer' style={{
                                            border: '1px solid #d9d9d9',
                                            background: getColorGradient(avgNum)
                                        }}
                                            onClick={(e) => {
                                                this.showModal(lessonPlanItem, idx)
                                            }}
                                            title='Click to view question'>
                                            <div className='text-center'>
                                                {avgStr}%
                                            </div>
                                        </td>
                                    })}
                                    <td className='sticky-right sticky-right-shadow'
                                        style={{ border: '1px solid #d9d9d9', borderRight: 'none', background: getColorGradient(parseFloat(this.getAverageForQuiz(this.props.quiz))) }}>
                                        <div className='text-center'>
                                            {this.getAverageForQuiz(this.props.quiz)}%
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='sticky-left background-dark-blue pb-1 font-fff font-bold'>DOK 1</td>
                                    {this.props.hasOwnProperty('showProgress') && this.props.showProgress ?
                                        <td className='background-dark-blue pb-1 font-fff font-bold'></td>
                                        : ''}
                                    {this.props.quiz.quiz.docs.map((question, index) => {
                                        return <td className='background-dark-blue pb-1 font-fff font-bold'></td>
                                    })}
                                    <td className='sticky-right background-dark-blue pb-1 font-fff font-bold'></td>
                                </tr>
                                {dok1Students.map((studentId, studentIndex) => {
                                    return this.getStudentView(studentId)
                                })}
                                {dok1Students.length === 0 ?
                                    <tr>
                                        <td colSpan={4} className='sticky-left'>
                                            There are currently no students reaching DOK 1. This can change as students complete the assessment.
                                        </td>
                                    </tr>
                                    : ''}
                                <tr>
                                    <td className='sticky-left sticky-left-shadow background-dark-blue pb-1 font-fff font-bold'>DOK 2</td>
                                    {this.props.hasOwnProperty('showProgress') && this.props.showProgress ?
                                        <td className='background-dark-blue pb-1 font-fff font-bold'></td>
                                        : ''}
                                    {this.props.quiz.quiz.docs.map((question, index) => {
                                        return <td className='background-dark-blue pb-1 font-fff font-bold'></td>
                                    })}
                                    <td className='sticky-right sticky-right-shadow background-dark-blue pb-1 font-fff font-bold'></td>
                                </tr>
                                {dok2Students.map((studentId, studentIndex) => {
                                    return this.getStudentView(studentId)
                                })}
                                {dok2Students.length === 0 ?
                                    <tr>
                                        <td colSpan={4} className='sticky-left'>
                                            There are currently no students reaching DOK 2. This can change as students complete the assessment.
                                        </td>
                                    </tr>
                                    : ''}
                                <tr>
                                    <td className='sticky-left sticky-left-shadow background-dark-blue pb-1 font-fff font-bold'>DOK 3</td>
                                    {this.props.hasOwnProperty('showProgress') && this.props.showProgress ?
                                        <td className='background-dark-blue pb-1 font-fff font-bold'></td>
                                        : ''}
                                    {this.props.quiz.quiz.docs.map((question, index) => {
                                        return <td className='background-dark-blue pb-1 font-fff font-bold'></td>
                                    })}
                                    <td className='sticky-right sticky-right-shadow background-dark-blue pb-1 font-fff font-bold'></td>
                                </tr>
                                {dok3Students.map((studentId, studentIndex) => {
                                    return this.getStudentView(studentId)
                                })}
                                {dok3Students.length === 0 ?
                                    <tr>
                                        <td colSpan={4} className='sticky-left'>
                                            There are currently no students reaching DOK 3. This can change as students complete the assessment.
                                        </td>
                                    </tr>
                                    : ''}

                            </tbody>
                        </table>

                        <Modal
                            title='View question'
                            width={748}
                            visible={this.state.visible}
                            onCancel={this.hideModal}
                            footer={null}
                        >
                            {this.state.viewingLessonPlanItem ?
                                <div className='question-container font-black font-20 border ant-shadow br-4 p-3'>
                                    <div className='font-30 flex flex-v-center space-between w-100'>
                                        <div className='inline-flex'>Question {this.state.viewingQuestionIdx + 1}</div>
                                    </div>
                                    <div>
                                        <DocumentDelegator
                                            document={this.state.viewingLessonPlanItem}
                                        />
                                    </div>
                                    <div className='mt-4'>
                                        <div className='font-16 font-bold'>
                                            This question is for the standard: {this.state.viewingLessonPlanItem.metadata.standard.standard}
                                        </div>
                                        {this.state.viewingLessonPlanItem.metadata.standard.hasOwnProperty('isPriority') &&
                                            this.state.viewingLessonPlanItem.metadata.standard.isPriority ?
                                            <div className='font-16'>*Priority Standard</div>
                                            : ''}
                                        <div className='mt-1 font-16'>{this.state.viewingLessonPlanItem.metadata.standard.title}</div>
                                    </div>
                                </div>
                                : ''}
                        </Modal>
                    </div>
                    : ''}
            </div>
        )
    }
}

export default DOKStandardBreakdown
