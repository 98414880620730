import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import CustomFooter from '../login/CustomFooter'
import DistrictStatsV2 from './../admin/DistrictStatsV2'
import { getIDFromURL, getQueryStringParam, flattenDoc } from '../Util'
import { Layout, Row, Col, Select, Spin, Tabs, Icon } from 'antd'
import ColType from '../Types'
import { db } from '../firebase/Firebase'
import PersonAvatar from '../customcomponents/PersonAvatar'
import SchoolReport from '../admin/SchoolReport'
import SchoolUploads from '../admin/SchoolUploads'
import SchoolPlanningReport from '../admin/SchoolPlanningReport'
import SchoolPracticeReport from '../admin/SchoolPracticeReport'
import SchoolProgressReport from '../admin/SchoolProgressReport'
import TeacherSummary from '../admin/TeacherSummary'
import SAMScoring from '../admin/SAMScoring'
const { Content } = Layout
const TabPane = Tabs.TabPane

class InstructionalFacilitatorSchoolView extends Component {
    state = {
        newPopoverVisible: false,
        accountPopoverVisible: false,
        adminId: '',
        prevSelectedId: null,
        selectedId: null,
        teacherId: '',
        studentId: '',
        teacher: null,
        student: null,
        teachers: [],
        teacherDict: {},
        students: [],
        studentDict: {},
        IEPGoals: [],
        IEPDict: {},
        calendarEvents: null,
        calendarDict: null,
        events: null,
        dataLoaded: false,
        dataRetrieved: false,
        studentReportData: {},
        teacherTab: 'schedule',
        loadingData: false,
        readOnly: true,
    }

    // Do fetch here
    componentDidMount() {
        document.title = 'Dashboard - Dot It'

        let instructionalFacilitatorId = getIDFromURL(window.location)
        let prevSelectedId = this.state.selectedId
        let selectedId = getQueryStringParam('s')
        let dataLoaded = this.state.dataLoaded

        // home page
        if (!selectedId && this.props.instructionalFacilitatorBaseProps.schools && this.props.instructionalFacilitatorBaseProps.schools.length > 0) {
            selectedId = this.props.instructionalFacilitatorBaseProps.schools[0].id
        }

        if (prevSelectedId !== selectedId) {
            dataLoaded = false
        }

        this.setState({
            instructionalFacilitatorId: instructionalFacilitatorId,
            selectedId: selectedId,
            prevSelectedId: selectedId,
            dataLoaded: dataLoaded,
        }, () => {
            this.getTeachers()
        })
    }

    componentWillReceiveProps(props, newProps) {
        let instructionalFacilitatorId = getIDFromURL(window.location)
        let prevSelectedId = this.state.selectedId
        let selectedId = getQueryStringParam('s')
        let dataLoaded = this.state.dataLoaded

        // home page
        if (!selectedId && this.props.instructionalFacilitatorBaseProps.schools && this.props.instructionalFacilitatorBaseProps.schools.length > 0) {
            selectedId = this.props.instructionalFacilitatorBaseProps.schools[0].id
        }

        if (prevSelectedId !== selectedId) {
            dataLoaded = false
        }

        this.setState({
            instructionalFacilitatorId: instructionalFacilitatorId,
            selectedId: selectedId,
            prevSelectedId: selectedId,
            dataLoaded: dataLoaded,
        }, () => {
            this.getTeachers()
        })
    }

    getTeachers = () => {
        if (this.state.dataLoaded) {
            return
        }

        if (this.state.selectedId && this.props.instructionalFacilitatorBaseProps.instructionalFacilitator) {
            this.setState({
                dataLoaded: true,
                dataRetrieved: false,
            })

            db.collection(ColType.teacher)
                .where('districtId', '==', this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.districtId)
                .where('schoolId', '==', this.state.selectedId)
                .get()
                .then((querySnapshot) => {
                    let teachers = []
                    let teacherDict = {}

                    querySnapshot.forEach((doc) => {
                        let teacher = flattenDoc(doc)
                        if (!(teacher.hasOwnProperty('isVisible') && !teacher.isVisible)) {
                            teachers.push(teacher)
                            teacherDict[teacher.id] = teacher
                        }
                    })

                    teachers.sort((a, b) => {
                        if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
                            return 1
                        } else {
                            return -1
                        }
                    })

                    this.setState({
                        teachers: teachers,
                        teacherDict: teacherDict,
                        dataRetrieved: true,
                    })
                })
        }
    }

    newVisibleChange = (visible) => {
        this.setState({ newPopoverVisible: visible })
    }

    accountVisibleChange = (visible) => {
        this.setState({ accountPopoverVisible: visible })
    }

    onChange = (value) => {
        console.log(`selected ${value}`)
    }

    onSelect = (value, option) => {
        console.log('on select', value, option)
        console.log('/teacher/student/' +
            this.props.person.id + '?student=' +
            option.key)

        this.props.history.push(
            {
                pathname: '/admin/school-summary/' +
                    this.props.person.id + '?district=' +
                    this.props.person.districtId + '&school=' +
                    option.key
            }
        )
    }

    onSchoolOrDistrictSelect = (schoolOrDistrict) => {
        if (this.props.instructionalFacilitatorBaseProps.district &&
            this.props.instructionalFacilitatorBaseProps.district.id === schoolOrDistrict) {
            this.props.history.push(
                {
                    pathname: '/admin/admin-home/' +
                        this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.id
                }
            )
        }
        else {
            this.props.history.push(
                {
                    pathname: '/admin/admin-home/' +
                        this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.id + '?s=' + schoolOrDistrict
                }
            )
        }
    }

    teacherClick = (teacher) => {
        this.setState({
            teacher: teacher,
            teacherId: teacher.id
        })

        this.getStudents(teacher)

        window.scroll(0, 0)
    }

    studentClick = (student) => {
        this.setState({
            student: student,
            studentId: student.id,
            studentReportData: {
                teacherId: this.state.teacher.id,
                studentId: student.id,
                teacher: this.state.teacher,
                student: student,
                teachers: this.state.teachers,
                students: this.state.students,
                studentDict: this.state.studentDict,
                // goals for this student
                IEPGoals: this.state.IEPGoals.filter((e) => e.studentId === student.id),
                IEPDict: this.state.IEPDict,
            }
        })

        window.scroll(0, 0)
    }

    onSchoolOrDistrictSelect = (schoolOrDistrict) => {
        this.setState({
            selectedOptionId: schoolOrDistrict
        })

        if (this.props.instructionalFacilitatorBaseProps.district &&
            this.props.instructionalFacilitatorBaseProps.district.id === schoolOrDistrict) {
            this.props.history.push(
                {
                    pathname: '/admin/admin-home/' +
                        this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.id
                }
            )
        }
        else {
            this.props.history.push(
                {
                    pathname: '/admin/admin-home/' +
                        this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.id + '?s=' + schoolOrDistrict
                }
            )
        }
    }

    getSchoolTypeIcon = (school) => {
        let schoolIcon = '/school-icons/1.png'
        if (school.hasOwnProperty('schoolType')) {
            if (school.schoolType === 'k8School') {
                schoolIcon = '/school-icons/2.png'
            } else if (school.schoolType === 'middleSchool') {
                schoolIcon = '/school-icons/3.png'
            } else if (school.schoolType === 'highSchool') {
                schoolIcon = '/school-icons/4.png'
            }
        }

        return schoolIcon
    }

    render() {
        let selectedId = this.props.schoolId
        let selectedStats = null
        let selectedDisplayName = ''
        if (selectedId && this.props.instructionalFacilitatorBaseProps.district) {

            if (this.props.instructionalFacilitatorBaseProps.schoolsDict &&
                this.props.instructionalFacilitatorBaseProps.schoolsDict.hasOwnProperty(selectedId)) {
                selectedDisplayName = this.props.instructionalFacilitatorBaseProps.schoolsDict[selectedId].schoolName
            }

            if (this.props.instructionalFacilitatorBaseProps.allStatOptionsDict.hasOwnProperty(selectedId)) {
                selectedStats = this.props.instructionalFacilitatorBaseProps.allStatOptionsDict[selectedId]
            }
        }

        return (
            <div>
                <div>
                    <Layout className="content relative layout-header-mt">
                        <Layout >
                            <Content>
                                <div>
                                    {this.state.instructionalFacilitatorId &&
                                        this.props.instructionalFacilitatorBaseProps.instructionalFacilitator &&
                                        this.props.instructionalFacilitatorBaseProps.district &&
                                        this.props.instructionalFacilitatorBaseProps.schoolsDict &&
                                        this.props.instructionalFacilitatorBaseProps.schools &&
                                        this.props.schoolId
                                        ?
                                        <div>
                                            <div className="sub-menu-width-vlg m-lr-auto mt-3">
                                                <Row gutter={32}>
                                                    <Col span={18}>
                                                        <div>
                                                            <div className='br-20 overflow-hidden mb-3 mt-2'>
                                                                <div className='br-4 relative pl-3 pr-3 pt-3 relative'
                                                                    style={{ height: '280px', }}>

                                                                    <div className="absolute-tl w-100 h-100" style={{ backgroundColor: '#333' }}>
                                                                    </div>
                                                                    <div className="absolute-tl w-100 h-100 image-box-mask"
                                                                        style={{ backgroundImage: 'url(/banner-2-new.png)' }}
                                                                    >
                                                                    </div>
                                                                    <div className='absolute-tl w-100 h-100'>
                                                                        <div className='h-100'>
                                                                            <div className='flex flex-v-center font-40 font-fff w-100 h-100 p-4'>
                                                                                <div className='w-100'>
                                                                                    <div className='font-40 font-bold font-fff'>{selectedDisplayName}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <Tabs defaultActiveKey="1" size='large' animated={false}>
                                                                <TabPane tab="School" key="1">
                                                                    <div className="mt-3 pl-2px pr-2px">

                                                                        <div>
                                                                            <h1 className='font-24 font-bold mb-1 mt-2'>Teachers</h1>
                                                                            {this.state.teachers ?
                                                                                <Select
                                                                                    showSearch
                                                                                    placeholder="Search for a teacher"
                                                                                    className='w-100 mb-2'
                                                                                    optionFilterProp="children"
                                                                                    onSelect={(teacherId) => {
                                                                                        if (this.props.instructionalFacilitatorBaseProps.instructionalFacilitator && teacherId && this.state.teacherDict.hasOwnProperty(teacherId)) {
                                                                                            let teacher = this.state.teacherDict[teacherId]
                                                                                            this.props.history.push({
                                                                                                pathname: '/instructional-facilitator/teacher/' + this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.id + '?s=' + teacher.schoolId + '&t=' + teacherId
                                                                                            })
                                                                                        }
                                                                                    }}
                                                                                    size="large"
                                                                                    filterOption={(input, option) => {
                                                                                        let person = option.props.children.props.person
                                                                                        let fullName = person.firstName + ' ' + person.lastName
                                                                                        return fullName.toLowerCase().includes(input.toLowerCase())
                                                                                    }}
                                                                                >
                                                                                    {this.state.teachers.map((teacher, idx) => {
                                                                                        return <Select.Option value={teacher.id} key={'search-' + teacher.id}>
                                                                                            <PersonAvatar
                                                                                                person={teacher}
                                                                                                avatarColor={'#d3adf7'}
                                                                                                avatarTextColor={'#000'}
                                                                                                containerClassName="font-16"
                                                                                                avatarClassName="font-16 font-bold mr-2"
                                                                                                personClassName="font-black font-16"
                                                                                            />
                                                                                        </Select.Option>
                                                                                    })}
                                                                                </Select>
                                                                                : ''}

                                                                            {!this.state.dataRetrieved ?
                                                                                <div className='text-center mt-2'>
                                                                                    <Spin size='large' />
                                                                                </div>
                                                                                : ''}
                                                                            {this.state.dataRetrieved && this.state.teachers && this.state.teachers.length === 0 ?
                                                                                <div className='font-16 font-bold mb-1'>No teachers</div>
                                                                                : ''}
                                                                            {this.state.dataRetrieved && this.state.teachers &&
                                                                                this.state.teachers.map((teacher, idx) => {
                                                                                    return <Link to={'/instructional-facilitator/teacher/' + this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.id + '?s=' + teacher.schoolId + '&t=' + teacher.id}
                                                                                        id={'right-menu-' + teacher.id}
                                                                                        key={'right-menu-key-' + teacher.id}>
                                                                                        <div className={"p-1 br-20 background-fff mb-1 ant-shadow ant-shadow-more-hover parent-hover relative w-100 font-16"}>
                                                                                            <PersonAvatar
                                                                                                person={teacher}
                                                                                                size={'large'}
                                                                                                avatarColor={'#d3adf7'}
                                                                                                avatarTextColor={'#000'}
                                                                                                containerClassName="font-20"
                                                                                                avatarClassName="font-20 font-bold mr-2"
                                                                                                personClassName="font-black font-20 ellipsis"
                                                                                                personStyle={{ maxWidth: '219px' }}
                                                                                            />
                                                                                            <TeacherSummary teacher={teacher} />
                                                                                        </div>
                                                                                    </Link>
                                                                                })}
                                                                        </div>
                                                                    </div>
                                                                </TabPane>
                                                                <TabPane tab="SAM Reports" key="sam-report">
                                                                    <div className="mt-3 pl-2px pr-2px">
                                                                        <SAMScoring
                                                                            districtId={this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.districtId}
                                                                            schoolId={this.props.schoolId}
                                                                            schoolName={this.props.instructionalFacilitatorBaseProps.schoolsDict.hasOwnProperty(this.props.schoolId) ?
                                                                                this.props.instructionalFacilitatorBaseProps.schoolsDict[this.props.schoolId].schoolName : ''}
                                                                        />
                                                                    </div>
                                                                </TabPane>
                                                                <TabPane tab="Plan Reports" key="plan-reports">
                                                                    <div className='mt-3'>
                                                                        <SchoolPlanningReport
                                                                            districtId={this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.districtId}
                                                                            schoolId={this.props.schoolId}
                                                                            person={this.props.instructionalFacilitatorBaseProps.instructionalFacilitator}
                                                                            accessLevel={ColType.instructionalFacilitator}
                                                                        />
                                                                    </div>
                                                                </TabPane>
                                                                <TabPane tab="Practice Reports" key="practice-reports">
                                                                    <div className='mt-3'>
                                                                        <SchoolPracticeReport
                                                                            districtId={this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.districtId}
                                                                            schoolId={this.props.schoolId}
                                                                            person={this.props.instructionalFacilitatorBaseProps.instructionalFacilitator}
                                                                            accessLevel={ColType.instructionalFacilitator}
                                                                        />
                                                                    </div>
                                                                </TabPane>
                                                                <TabPane tab="Progress Reports" key="progress-reports">
                                                                    <div className='mt-3'>
                                                                        <SchoolProgressReport
                                                                            districtId={this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.districtId}
                                                                            schoolId={this.props.schoolId}
                                                                            person={this.props.instructionalFacilitatorBaseProps.instructionalFacilitator}
                                                                            accessLevel={ColType.instructionalFacilitator}
                                                                        />
                                                                    </div>
                                                                </TabPane>
                                                                <TabPane tab="Full Reports" key="full-reports">
                                                                    <div className='mt-3'>
                                                                        <SchoolReport
                                                                            districtId={this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.districtId}
                                                                            schoolId={this.props.schoolId}
                                                                            person={this.props.instructionalFacilitatorBaseProps.instructionalFacilitator}
                                                                            accessLevel={ColType.instructionalFacilitator}
                                                                        />
                                                                    </div>
                                                                </TabPane>
                                                                <TabPane tab="Uploads" key="school-uploads">
                                                                    <div className='mt-3'>
                                                                        <SchoolUploads
                                                                            districtId={this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.districtId}
                                                                            schoolId={this.props.schoolId}
                                                                            person={this.props.instructionalFacilitatorBaseProps.instructionalFacilitator}
                                                                            accessLevel={ColType.instructionalFacilitator}
                                                                        />
                                                                    </div>
                                                                </TabPane>
                                                            </Tabs>










                                                        </div>

                                                    </Col>
                                                    <Col span={6}>
                                                        {this.props.instructionalFacilitatorBaseProps &&
                                                            this.props.instructionalFacilitatorBaseProps.instructionalFacilitator &&
                                                            this.props.instructionalFacilitatorBaseProps.district &&
                                                            (this.props.instructionalFacilitatorBaseProps.district.id === "GCj1Hg1H5vipzer4pED4" ||
                                                                this.props.instructionalFacilitatorBaseProps.district.id === '5410a3828725bd2f0d00100d' ||
                                                                this.props.instructionalFacilitatorBaseProps.district.id === '64bec570267c8bfeea81f9ea') ?
                                                            <div className='flex w-100 mb-2'>
                                                                <Link
                                                                    to={'/instructional-facilitator/instructional-facilitator-workshop-observation-tool/documents/' + this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.id}
                                                                    className={"ant-btn ant-btn-primary lg-btn inline-flex ml-auto"}>

                                                                    <div className="va-minus-4 font-20">
                                                                        <span>Workshop Observation Tool</span>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            : ''}


                                                        <h1 className='font-24 font-bold mb-1'>Schools</h1>

                                                        {this.props.instructionalFacilitatorBaseProps.schools ?
                                                            <Select
                                                                showSearch
                                                                placeholder="Search for a school"
                                                                className='w-100 mb-2'
                                                                optionFilterProp="children"
                                                                onSelect={(schoolId) => {
                                                                    if (this.props.instructionalFacilitatorBaseProps.instructionalFacilitator && schoolId) {
                                                                        let idx = 0
                                                                        if (this.props.instructionalFacilitatorBaseProps.schools) {
                                                                            idx = this.props.instructionalFacilitatorBaseProps.schools.findIndex((school) => {
                                                                                return school.id === schoolId
                                                                            })
                                                                        }
                                                                        let url = '/instructional-facilitator/instructional-facilitator-home/'
                                                                        let qs = ''
                                                                        if (idx > 0) {
                                                                            url = '/instructional-facilitator/school/'
                                                                            qs = '?s=' + schoolId
                                                                        }
                                                                        let fullURL = url + this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.id + qs
                                                                        this.props.history.push({
                                                                            pathname: fullURL
                                                                        })
                                                                    }
                                                                }}
                                                                size="large"
                                                                filterOption={(input, option) => {
                                                                    if (option.props.children.length < 2) {
                                                                        return false
                                                                    }
                                                                    return option.props.children[1].toLowerCase().includes(input.toLowerCase())
                                                                }}
                                                            >
                                                                {this.props.instructionalFacilitatorBaseProps.schools &&
                                                                    this.props.instructionalFacilitatorBaseProps.schools.map((school, idx) => {
                                                                        return <Select.Option value={school.id} key={'search-' + school.id}>
                                                                            <img src={this.getSchoolTypeIcon(school)} alt='school-icon' width={32} className='mr-1' />
                                                                            {school.schoolName}
                                                                        </Select.Option>
                                                                    })}
                                                            </Select>
                                                            : ''}

                                                        {this.props.instructionalFacilitatorBaseProps.schools && this.props.instructionalFacilitatorBaseProps.schools.length === 0 ?
                                                            <div className='font-16 font-bold mb-1'>No schools</div>
                                                            : ''}
                                                        {this.props.instructionalFacilitatorBaseProps.schools &&
                                                            this.props.instructionalFacilitatorBaseProps.schools.map((school, idx) => {
                                                                let url = '/instructional-facilitator/instructional-facilitator-home/'
                                                                let qs = ''
                                                                if (idx > 0) {
                                                                    url = '/instructional-facilitator/school/'
                                                                    qs = '?s=' + school.id
                                                                }
                                                                return <Link to={url + this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.id + qs}
                                                                    id={'right-menu-' + school.id}
                                                                    key={'right-menu-key-' + school.id}>
                                                                    <div className={"p-1 br-20 background-fff mb-1 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center font-20 font-black"}>
                                                                        <img src={this.getSchoolTypeIcon(school)} alt='school-icon' width={50} className='mr-1' />
                                                                        {school.schoolName}
                                                                    </div>
                                                                </Link>
                                                            })}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        : ''}
                                </div>
                            </Content>
                        </Layout>
                    </Layout>
                </div>
                <CustomFooter />
            </div>
        )
    }
}

export default InstructionalFacilitatorSchoolView