import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import CustomFooter from '../login/CustomFooter'
import DistrictStatsV2 from './DistrictStatsV2'
import SchoolReport from './SchoolReport'
import ColType from '../Types'
import { db, firebase } from '../firebase/Firebase'
import { getIDFromURL, flattenDoc, getQueryStringParam } from '../Util'
import { Layout, Row, Col, message, Select, Icon, Tabs } from 'antd'
import PersonAvatar from '../customcomponents/PersonAvatar'
import SchoolUploads from './SchoolUploads'
import SchoolProgressReport from './SchoolProgressReport'
import SchoolPlanningReport from './SchoolPlanningReport'
import SchoolPracticeReport from './SchoolPracticeReport'
import TeacherSummary from './TeacherSummary'
import SAMScoring from './SAMScoring'
const { Content } = Layout
const TabPane = Tabs.TabPane

const errorMessage = (description) => {
  message.error(description)
}

class AdminHome extends Component {
  state = {
    newPopoverVisible: false,
    accountPopoverVisible: false,
    adminId: '',
    selectedId: null,
    teacherId: '',
    studentId: '',
    schoolId: '',
    teacher: null,
    student: null,
    teachers: null,
    teacherDict: null,
    students: [],
    studentDict: {},
    IEPGoals: [],
    IEPDict: {},
    calendarEvents: null,
    calendarDict: null,
    events: null,
    studentReportData: {},
    teacherTab: 'schedule',
    loadingData: false,
    loadedData: false,
    readOnly: true,
  }

  // Do fetch here
  componentDidMount() {
    document.title = 'Dashboard - Dot It'

    let adminId = getIDFromURL(window.location)
    let schoolId = getQueryStringParam('s')

    this.setState({
      adminId: adminId,
      schoolId: schoolId,
    })

    this.getTeachers()
  }

  componentWillReceiveProps(nextProps) {
    this.getTeachers()
  }

  getTeachers = () => {
    let schoolId = getQueryStringParam('s')

    if (this.state.loadedData) {
      return
    }

    if (!(schoolId && this.props.adminBaseProps.admin)) {
      return
    }

    this.setState({
      loadedData: true,
    })

    db.collection(ColType.teacher)
      .where('districtId', '==', this.props.adminBaseProps.admin.districtId)
      .where('schoolId', '==', schoolId)
      .get()
      .then((querySnapshot) => {
        var teachers = []
        var teacherDict = {}

        querySnapshot.forEach((doc) => {
          var teacher = flattenDoc(doc)
          if (!(teacher.hasOwnProperty('isVisible') && !teacher.isVisible)) {
            teachers.push(teacher)
            teacherDict[teacher.id] = teacher
          }
        })

        teachers.sort((a, b) => {
          if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
            return 1
          } else {
            return -1
          }
        })

        this.setState({
          teachers: teachers,
          teacherDict: teacherDict,
          loadingData: false,
        })
      })
  }

  newVisibleChange = (visible) => {
    this.setState({ newPopoverVisible: visible })
  }

  accountVisibleChange = (visible) => {
    this.setState({ accountPopoverVisible: visible })
  }

  signOut = () => {
    firebase.auth().signOut().then(function () {
      console.log('Signed Out')
      //sending users to the sign in page after signing out for now.
      window.location.href = "/sign-in"
    }, function (error) {
      console.error('Sign Out Error', error)
      errorMessage("There was an error signing you out. Please try again.")
    })
  }

  onChange = (value) => {
    console.log(`selected ${value}`)
  }

  onSelect = (value, option) => {
    console.log('on select', value, option)
    console.log('/teacher/student/' +
      this.props.person.id + '?student=' +
      option.key)

    this.props.history.push(
      {
        pathname: '/admin/school-summary/' +
          this.props.person.id + '?district=' +
          this.props.person.districtId + '&school=' +
          option.key
      }
    )
  }

  onSchoolOrDistrictSelect = (schoolOrDistrict) => {
    if (this.props.adminBaseProps.district &&
      this.props.adminBaseProps.district.id === schoolOrDistrict) {
      this.props.history.push(
        {
          pathname: '/admin/admin-home/' +
            this.props.adminBaseProps.admin.id
        }
      )
    }
    else {
      this.props.history.push(
        {
          pathname: '/admin/admin-home/' +
            this.props.adminBaseProps.admin.id + '?s=' + schoolOrDistrict
        }
      )
    }
  }

  teacherClick = (teacher) => {
    this.setState({
      teacher: teacher,
      teacherId: teacher.id
    })

    this.getStudents(teacher)

    window.scroll(0, 0)
  }

  studentClick = (student) => {
    this.setState({
      student: student,
      studentId: student.id,
      studentReportData: {
        teacherId: this.state.teacher.id,
        studentId: student.id,
        teacher: this.state.teacher,
        student: student,
        teachers: this.state.teachers,
        students: this.state.students,
        studentDict: this.state.studentDict,
        // goals for this student
        IEPGoals: this.state.IEPGoals.filter((e) => e.studentId === student.id),
        IEPDict: this.state.IEPDict,
      }
    })

    window.scroll(0, 0)
  }

  onSchoolOrDistrictSelect = (schoolOrDistrict) => {
    this.setState({
      selectedOptionId: schoolOrDistrict
    })

    if (this.props.adminBaseProps.district &&
      this.props.adminBaseProps.district.id === schoolOrDistrict) {
      this.props.history.push(
        {
          pathname: '/admin/admin-home/' +
            this.props.adminBaseProps.admin.id
        }
      )
    }
    else {
      this.props.history.push(
        {
          pathname: '/admin/admin-home/' +
            this.props.adminBaseProps.admin.id + '?s=' + schoolOrDistrict
        }
      )
    }
  }

  render() {
    return (
      <div>
        <div>
          <Layout className="content relative">
            <Layout >
              <Content className='layout-header-mt'>
                <div>
                  {this.state.adminId &&
                    this.props.adminBaseProps.admin &&
                    this.props.adminBaseProps.district &&
                    this.props.adminBaseProps.schoolsDict
                    ?
                    <div>
                      <div className="sub-menu-width-vlg m-lr-auto mt-3">
                        <div>
                          <Row gutter={32}>
                            <Col span={24}>


                              <div>
                                <div className="w-100 flex flex-v-center mb-2 font-16 font-bold">
                                  <Link to={'/admin/admin-home/' + this.props.adminBaseProps.admin.id}>
                                    {this.props.adminBaseProps.district.district}
                                  </Link>
                                  <Icon type="right" className="ml-1 mr-1 font-14" />
                                  <span>
                                    {this.props.adminBaseProps.schoolsDict.hasOwnProperty(this.state.schoolId) ?
                                      this.props.adminBaseProps.schoolsDict[this.state.schoolId].schoolName : ''}
                                  </span>
                                </div>
                              </div>
                              <div className='relative'>
                                <div className='br-20 overflow-hidden mb-3 mt-2'>
                                  <div className='br-4 relative pl-3 pr-3 pt-3 relative'
                                    style={{ height: '280px', }}>

                                    <div className="absolute-tl w-100 h-100" style={{ backgroundColor: '#333' }}>
                                    </div>
                                    <div className="absolute-tl w-100 h-100 image-box-mask"
                                      style={{ backgroundImage: 'url(/banner-2-new.png)' }}
                                    >
                                    </div>
                                    <div className='absolute-tl w-100 h-100'>
                                      <div className='h-100'>
                                        <div className='flex flex-v-center font-40 font-fff w-100 h-100 p-4'>
                                          <div className='w-100'>
                                            <div className='font-40 font-bold font-fff'>
                                              {this.props.adminBaseProps.schoolsDict.hasOwnProperty(this.state.schoolId) ?
                                                this.props.adminBaseProps.schoolsDict[this.state.schoolId].schoolName : ''}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='font-24 font-bold mb-2'>Teachers</div>
                              {this.state.teachers ?
                                <Select
                                  showSearch
                                  placeholder="Search for a teacher"
                                  className='w-100 mb-2'
                                  optionFilterProp="children"
                                  onSelect={(teacherId) => {
                                    if (this.props.adminBaseProps.admin && this.state.schoolId && teacherId) {
                                      this.props.history.push({
                                        pathname: '/admin/teacher/' + this.props.adminBaseProps.admin.id + '?s=' + this.state.schoolId + '&t=' + teacherId
                                      })
                                    }
                                  }}
                                  size="large"
                                  filterOption={(input, option) => {
                                    let person = option.props.children.props.person
                                    let fullName = person.firstName + ' ' + person.lastName
                                    return fullName.toLowerCase().includes(input.toLowerCase())
                                  }}
                                >
                                  {this.state.teachers.map((teacher, idx) => {
                                    return <Select.Option value={teacher.id} key={'search-' + teacher.id}>
                                      <PersonAvatar
                                        person={teacher}
                                        avatarColor={'#d3adf7'}
                                        avatarTextColor={'#000'}
                                        containerClassName="font-16"
                                        avatarClassName="font-16 font-bold mr-2"
                                        personClassName="font-black font-16"
                                      />
                                    </Select.Option>
                                  })}
                                </Select>
                                : ''}

                              {this.state.teachers && this.state.teachers.length === 0 ?
                                <div className='font-16 font-bold mb-1'>No teachers</div>
                                : ''}
                              {this.state.teachers &&
                                this.state.teachers.map((teacher, idx) => {
                                  return <Link to={'/admin/teacher/' + this.props.adminBaseProps.admin.id + '?s=' + this.state.schoolId + '&t=' + teacher.id}
                                    id={'right-menu-' + teacher.id}>
                                    <div className={"p-1 br-20 background-fff mb-1 ant-shadow ant-shadow-more-hover parent-hover relative font-16 w-100"}>
                                      <PersonAvatar
                                        person={teacher}
                                        size={'large'}
                                        avatarColor={'#d3adf7'}
                                        avatarTextColor={'#000'}
                                        containerClassName="font-20"
                                        avatarClassName="font-20 font-bold mr-1"
                                        personClassName="font-black font-20"
                                      />
                                      <TeacherSummary teacher={teacher} />
                                    </div>
                                  </Link>
                                })}
                            </Col>
                          </Row>


                        </div>
                      </div>

                    </div>
                    : ''}
                </div>
              </Content>
            </Layout>
          </Layout>
        </div>
        <CustomFooter />
      </div>
    )
  }
}

export default AdminHome